import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Button } from "react-bootstrap"
import { Select, Box, Chip, MenuItem } from '@mui/material'

import { getTranslation } from 'utils/renderLanguage'
import { roleOptions, statusOptions } from 'utils/dictionary'

const fieldList = ( mode, projectDictionary ) => [
  { label: 'Display Name', value: 'display_name', type: 'text' },
  { label: 'Email', value: 'email', type: 'text' },
  ...mode === 'create' ? [
    { label: 'Password', value: 'password', type: 'password' }
  ] : [
    { label: 'Project', value: 'project', type: 'multi-select', options: projectDictionary, disabled: mode === 'update' }
  ],
  { label: 'Mobile No.', value: 'mobile_no', type: 'text' },
  { label: 'Role', value: 'role', type: 'select', options: roleOptions, disabled: mode === 'update' },
  { label: 'Status', value: 'status', type: 'select', options: statusOptions }
]

const UserForm = props => {

  const fieldData = fieldList( props.mode, props.projectDictionary )
  const [ userDetails, setUserDetails ] = useState({
    display_name: '',
    email: '',
    password: '',
    mobile_no: '',
    role: '',
    status: '',
  })
  const [error, setError] = useState({})

  useEffect( () => {
    if( props.selectedUser && props.mode === 'update' ){
      setUserDetails( props.selectedUser )
    }
  }, [])

  const handleSubmit = () => Promise.all([
    fieldData.map( item => {
      if( userDetails[ item.value ] === '' ){
        setError({ [ item.value ]: `${ item.label } should not left empty.` })
      }
    })
  ]).then( () => {
    if( !_.values( userDetails ).some( x => !x || x === '' ) ){
      if( props.mode === 'create' )
        props.createNewUser( userDetails )
      else 
        props.updateUser( userDetails )
    }
  })

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( userDetails )
    temp[ context ] = val
    setUserDetails( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body className='row'>
        {
          fieldData.map(( item, index ) => {
            if( item.type === 'select' ){
              return(
                <Form.Group key={ index } className="mt-3 col-lg-6">
                  <Form.Label>{ getTranslation( props.language, item.label ) }</Form.Label>
                  <Form.Select 
                    aria-label={ getTranslation( props.language, item.label ) }
                    disabled={ item.disabled }
                    value={ userDetails[ item.value ] }
                    onChange={ e => onChangeField( e.target.value, item.value )}>
                  <option hidden value={ '' }>Select</option>
                  {
                    item.options.map(( option, index ) => (
                      <option key={ index } value={ option.value }>{ option.label }</option>
                    ))
                  }
                  </Form.Select>
                </Form.Group>
              )
            } else if( item.type === 'multi-select' ){
              return(
                <Form.Group key={ index } className="mt-3 col-lg-6">
                  <Form.Label>{ getTranslation( props.language, item.label ) }</Form.Label>
                  {
                    item.disabled ? (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {
                          ( userDetails[ item.value ] && userDetails[ item.value ].length > 0 ) && userDetails[ item.value ].map(( value, index ) => {
                            let temp = _.find( item.options, { value: value })
                            return(
                              <Chip color="primary" key={ index } label={ temp?.label } />
                            )
                          })
                        }
                        {
                          !( userDetails[ item.value ] && userDetails[ item.value ].length > 0 ) && (
                            <>
                              {
                                userDetails.role.includes( 'Admin' )
                                  ? <Chip color="success" label={ 'All project is accessible by admin' } />
                                  : <Chip color="error" label={ 'No Project Assigned' } />
                              }
                            </>
                            
                          )
                        }
                      </Box>
                    ) : (
                      <Select
                        fullWidth
                        multiple
                        style={{ borderRadius: `0.375rem`, borderColor: '#ced4da' }}
                        value={ userDetails[ item.value ] || [] }
                        onChange={ e => onChangeField( e.target.value, item.value ) }
                        renderValue={ ( selected ) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            { 
                              selected.map(( value, index ) => {
                                let temp = _.find( item.options, { value: value })
                                return(
                                  <Chip color="primary" key={ index } label={ temp.label } />
                                )
                              })
                            }
                          </Box>
                        )}
                      >
                        {
                          item.options.map(( option, index ) => (
                          <MenuItem key={ index } value={ option.value } >{ option.label }</MenuItem>
                        ))}
                      </Select>
                    )
                  }
                </Form.Group>
              )
            } else {
              return(
                <Form.Group key={ index } className='mt-3 col-lg-6'>
                  <Form.Label>{ getTranslation( props.language, item.label ) }</Form.Label>
                  <Form.Control
                    type={ item.type }
                    placeholder={ getTranslation( props.language, item.label ) }
                    disabled={ item.disabled }
                    value={ userDetails[ item.value ] }
                    onChange={ e => onChangeField( e.target.value, item.value ) }
                  />
                  { error[ item.value ] && <Form.Text>{ error[ item.value ] }</Form.Text>}
                </Form.Group>
              )
            }
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          { getTranslation( props.language, 'Close' ) }
        </Button>
        <Button disabled={ _.values( userDetails ).some( x => !x || x === '' ) } variant="primary" onClick={ handleSubmit }>
          { getTranslation( props.language, 'Submit' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserForm;
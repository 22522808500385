import React from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

import './index.scss'

const SliderCard = props => {

  const scrollToLast = () => {
    const elmntToView = document.getElementById( `slider-card-${ props.dataList.length - 1 }` );
    elmntToView.scrollIntoView({behavior: "smooth"}); 
  }

  const scrollToStart = () => {
    const elmntToView = document.getElementById( `slider-card-1` );
    elmntToView.scrollIntoView({behavior: "smooth"}); 
  }

  return(
    <div className='slider-card-cont'>
      {
        ( props.dataList && props.dataList.length > 0 ) && props.dataList.map(( item, index ) => {
          return(
            <div id={ `slider-card-${ index }` } key={ index } className={ 'slider-card' }>
              { props.renderCardContent( item ) }
            </div>
          )
        })
      }
      <button className='slider-card-navigate-button' onClick={ () => scrollToStart() }>
        <KeyboardArrowLeft />
      </button>
      <button className='slider-card-navigate-button' style={{ right: 10 }} onClick={ () => scrollToLast() }>
        <KeyboardArrowRight />
      </button>
    </div>
  )
}

export default SliderCard;
import React, { useEffect } from 'react'
import localStorage from 'localStorage'
import { PictureAsPdfRounded, UploadFile } from '@mui/icons-material'

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import EmptyState from 'components/EmptyState'

import CreateAchievement from './form'
import AchievementSubmission from './submission'

import KIPHOC from './actions'
import { badgeStatus } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )
const columnData = [
  { header: getTranslation( language, 'No.' ), renderColumn: ( data, index ) => index + 1, containerStyle: { width: '30%' } },
  { header: getTranslation( language, 'Achievement' ), accessor: 'achievement_title', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Description' ), accessor: 'achievement_description', containerStyle: { width: '150%' },
    renderColumn: rowData => <p style={{ textAlign: 'justify' }}>{ rowData.achievement_description }</p>
  },
  { header: getTranslation( language, 'Support Document' ), containerStyle: { width: '50%' },
    renderColumn: rowData => {
      if( rowData.achievement_document_url ){
        return(
          <button className='btn' onClick={ () => window.open( rowData.achievement_document_url, '_blank' ) }>
            <PictureAsPdfRounded style={{ color: '#cb1515' }}/>
          </button>
        )
      } else {
        return '-'
      }
    }
  },
  { header: getTranslation( language, 'Submission Date' ), accessor: 'achievement_submission_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Notes' ), accessor: 'achievement_submission_note', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Status' ), containerStyle: { width: '50%' }, 
    renderColumn: rowData => <p className='badge__status' style={ badgeStatus( rowData.achievement_status ) }>{ getTranslation( language, rowData.achievement_status ) }</p>
  },
]

const Achievement = props => {

  useEffect( () => {
    props.getAchievements( props.projectDetail.achievement_indication )
  }, [ props.projectDetail ])

  return(
    <>
      <div className={ 'd-flex align-items-end mb-2' }>
        <h2 style={{ fontSize: 20, fontWeight: 500 }}>{ getTranslation( language, 'Project Achievement' ) }</h2>
        <button
          className="btn btn-primary"
          style={{ marginLeft: 'auto' }}
          onClick={ () => props.onChangeAchievementHOC( true, 'showCreateModal' ) }>
          { getTranslation( language, 'Create Achievement' ) }
        </button>
      </div>
      {( props.achievements && props.achievements.length > 0 ) && (
        <PaginatedTable
          rowData={ props.achievements }
          columnData={ columnData }
          actionsColumn={[{
            name: 'Submission',
            renderActionIcon: <UploadFile />,
            onShow: rowData => [ 'Pending', 'On-Progress' ].indexOf( rowData.achievement_status ) > -1,
            onClick: rowData => Promise.all([
              props.onChangeAchievementHOC( rowData, 'selectedAchievement' )
            ]).then( () => {
              props.onChangeAchievementHOC( true, 'showSubmissionModal' )
            })
          }]}
        />
      )}
      {
        !( props.achievements && props.achievements.length > 0 ) && <EmptyState 
          title={ 'No Achievement define' }
          description={ 'Click "Create Achievement" to create.' } />
      }
      {
        props.showCreateModal && <CreateAchievement
          mode={ 'create' }
          title={ getTranslation( language, 'Create Achievement' ) }
          language={ language }
          projectDetail={ props.projectDetail }
          createNewAchievement={ props.createNewAchievement }
          setOpen={ val => props.onChangeAchievementHOC( val, 'showCreateModal' ) }  
        />
      }
      {
        props.showSubmissionModal && <AchievementSubmission
          title={ getTranslation( language, 'Achievement Submission' ) }
          selectedAchievement={ props.selectedAchievement }
          projectDetail={ props.projectDetail }
          putAchievementSubmission={ props.putAchievementSubmission }
          setOpen={ val => props.onChangeAchievementHOC( val, 'showSubmissionModal' ) }  
        />
      }
      { props.onLoadAchievement && <LoadingModal /> }
    </>
  )
}

export default KIPHOC( Achievement )
export const cardList = [
  { label: 'Project Started', value: 'start_year' },
  { label: 'Next Submission Date', value: 'submission_date' },
  { label: 'Current Stage ', value: 'current_stage' },
  { label: 'Current Milestone', value: 'current_milestone' },
]

export const projectField = [
  { label: 'Project Name', value: 'project_name' },
  { label: 'Description', value: 'project_description' },
  { label: 'Implementing Agency', value: 'implementing_agency' },
  { label: 'Assessor', value: 'assessor' },
  { label: 'Project Leader', value: 'project_leader' },
  { label: 'Start Year', value: 'start_year' },
  { label: 'Funder', value: 'funder' },
  { label: 'Current Stage', value: 'current_stage' },
  { label: 'State', value: 'state' },
  { label: 'Status', value: 'status' }
]

export const milestoneFieldData = [
  { label: 'Milestone Title', value: 'milestone_title', type: 'text' },
  { label: 'KPI(%)', value: 'milstone_kpi', type: 'number' },
  { label: 'Start Date', value: 'milestone_start_date', type: 'date' },
  { label: 'End Date', value: 'milestone_end_date', type: 'date' },
]

export const stageFieldData = [
  { label: 'Stage Title', value: 'stage_name', type: 'text' },
  { label: 'Stage Description', value: 'stage_description', type: 'textarea' },
]

export const updateMilestoneField = [
  { label: 'KPI(%)', value: 'milstone_actual_kpi', type: 'number' },
  { label: 'Start Date', value: 'milestone_actual_start_date', type: 'date' },
  { label: 'End Date', value: 'milestone_actual_end_date', type: 'date' },
]
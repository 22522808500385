import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      showCreateModal: false,
      showSubmissionModal: false,

      achievements: [],
      selectedAchievement: {}
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeAchievementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getAchievements = achievements => Get(
      `/achievement-getAchievements?achievements=${ JSON.stringify( achievements ) }`,
      this.getAchievementsSuccess,
      this.getAchievementsError,
      this.load
    )
    getAchievementsSuccess = payload => this.setState({ achievements: payload.data.achievement })
    getAchievementsError = error => this.requestError( error )

    createNewAchievement = dataToSubmit => Post(
      `/achievement-newAchievement`,
      dataToSubmit,
      this.createNewAchievementSuccess,
      this.createNewAchievementError,
      this.load
    )
    createNewAchievementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    createNewAchievementError = error => this.requestError( error )

    putAchievementSubmission = dataToSubmit => Post(
      `/achievement-achievementSubmission`,
      dataToSubmit,
      this.putAchievementSubmissionSuccess,
      this.putAchievementSubmissionError,
      this.load
    )
    putAchievementSubmissionSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showSubmissionModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    putAchievementSubmissionError = error => this.requestError( error )

    deleteAchievement = id => Delete (
      `/achievement-deleteAchievement?id=${ id }`,
      this.deleteAchievementSuccess,
      this.deleteAchievementError,
      this.load
    )
    deleteAchievementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showDeleteConfirmation: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    deleteAchievementError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadAchievement={ this.state.loading }

            getAchievements ={ this.getAchievements  }
            deleteAchievement={ this.deleteAchievement }
            createNewAchievement={ this.createNewAchievement }
            putAchievementSubmission={ this.putAchievementSubmission }
            onChangeAchievementHOC={ this.onChangeAchievementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC
import React, { useEffect, useState } from "react";
import { NavigateBefore } from "@mui/icons-material";
import { useParams, useOutletContext } from 'react-router-dom';

import Tab from 'components/Tab'
import KPI from "./containers/KPI"
import Overview from "./containers/overview";
import Financial from "./containers/Financial";
import LoadingModal from "components/LoadingModal";
import StageNMilestone from "./containers/StageNMilestone";
import SiteAudit from 'container/Dashboard/Assessor/SiteAudit'
import ProjectAssessment from 'container/Dashboard/Assessor/ProjectAssessment'
import OverallAssessment from 'container/Dashboard/Assessor/OverallAnalysis'

import ProjectDetailsHOC from './actions'
import { getTranslation } from 'utils/renderLanguage'

import './index.scss'

const ProjectViewForm = props => {

  const { language, onNavigate } = useOutletContext()
  const { project_id: selectedProjectId } = useParams()
  const [ selectedTab, setSelectedTab ] = useState( 'Overview' )

  useEffect( () => {
    props.getProjectDetail( selectedProjectId )
  }, [ selectedProjectId ])
  
  return(
    <>
      <div className="d-flex align-items-center">
        <button className="back-button" onClick={ () => onNavigate( -1 ) }>
          <NavigateBefore/>
        </button>
        <h4>
          { getTranslation( language, 'Project Details' ) }
          <span style={{ fontSize: 12, marginLeft: 5 }}>({ selectedProjectId })</span>
        </h4>
      </div>
      <Tab
        selections={ [ 'Overview', 'Stage & Milestone', 'KPI', 'Disbursement', 'Project Progress Assessment', 'Site Audit Assessment', 'Overall Monitoring Analysis' ] }
        selectedItem={ selectedTab }
        onSelect={ setSelectedTab }
      />
      <hr/>
      { selectedTab === 'Overview' && <Overview { ...props } language={ language } setSelectedTab={ setSelectedTab }/> }
      { selectedTab === 'Stage & Milestone' && <StageNMilestone { ...props } language={ language } /> }
      { selectedTab === 'KPI' && <KPI { ...props } language={ language } /> }
      { selectedTab === 'Disbursement' && <Financial { ...props } language={ language } /> }
      { selectedTab === 'Project Progress Assessment' && <ProjectAssessment { ...props } language={ language } /> }
      { selectedTab === 'Site Audit Assessment' && <SiteAudit { ...props } language={ language } /> }
      { selectedTab === 'Overall Monitoring Analysis' && <OverallAssessment { ...props } language={ language } /> }
      { props.onLoadProjectDetails && <LoadingModal /> }
    </>
  )
}

export default ProjectDetailsHOC( ProjectViewForm );
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form } from "react-bootstrap"
import localStorage from 'localStorage'
import DatePicker  from 'react-datepicker'
import { FaFilePdf } from 'react-icons/fa'
import { useParams, useOutletContext } from 'react-router-dom';
import { Plagiarism, NavigateBefore, Clear } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import PreviewModal from '../../components/preview'

import { getTranslation } from 'utils/renderLanguage'
import OverallAssessmentHOC from './actions'
import { overallAssessmentField } from '../assets'
import '../index.scss'

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const language = localStorage.getItem( 'english' )

const ViewAssessment = props => {
  
  const outletContext = useOutletContext()
  const {
    project_id: selectedProjectId,
    overall_id
  } = useParams()

  const [ numPages, setNumPages ] = useState( null )
  const [ pageNumber, setPageNumber ] = useState( 1 )

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  
  useEffect( () => {
    Promise.all([
      props.getProjectDetail( selectedProjectId )
    ]).then( () => {
      props.getOverallAssessment( overall_id )
    })
  }, [])

  const renderFieldInput = ( item, section, index ) => {
    if( item.type === 'textarea' ) {
      return(
        <Form.Group key={ index } className='mt-3 mb-4'>
          <Form.Label style={{ fontWeight: 500 }}>{ getTranslation( language, item.label ) }</Form.Label>
          <Form.Control
            as='textarea'
            type={ 'text' }
            style={{ minHeight: 100 }}
            disabled={ true }
            placeholder={ getTranslation( language, item.label ) }
            value={ props.overallAssessment[ section ][ item.value ] }
          />
        </Form.Group>
      )
    } else {
      return(
        <Form.Group key={ index } className='mt-3 mb-5'>
          <Form.Label style={{ fontWeight: 500 }}>{ getTranslation( language, item.label ) }</Form.Label>
          <Form.Control
            type={ item.type }
            placeholder={ getTranslation( language, item.label ) }
            maxLength={ item.type === 'number' ? 3 : '' }
            disabled={ true }
            value={ props.overallAssessment[ section ][ item.value ] }
          />
        </Form.Group>
      )
    }
  }
  
  const renderCriteriaColumn = () => {

    return(
      <>
        {
          overallAssessmentField.map(( item, index ) => {
            return(
              <div key={ index } className='site-audit__section-cont'>
                <label className='title'>{ getTranslation( language, item.label ) }</label>
                {
                  item.children.map(( child , childIndex ) => (
                    renderFieldInput( child, item.value, childIndex )
                  ))
                }
              </div>
            )
          })
        }
      </>
    )
  }
  
  return(
    <>
      <div className="d-flex align-items-center mb-5">
        <button className="back-button" onClick={ () => outletContext.onNavigate( -1 ) }>
          <NavigateBefore/>
        </button>
        <h4>
          { getTranslation( language, 'Overall Monitoring Assessment' ) }
          <span style={{ fontSize: 14, marginLeft: 5 }}>/ { selectedProjectId }</span>
        </h4>
      </div>
      <div className='d-flex justify-content-between mb-4'>
        <div className='year-selection'>
          <label>{ getTranslation( language, 'Year' ) }</label>
          <DatePicker
            className='form-date-picker'
            selected={ props.selectedYear }
            disabled={ true }
            onChange={ date => props.onChangeOverallAssessmentHOC( date, 'selectedYear' ) }
            dateFormat="QQQ, yyyy"
            showQuarterYearPicker
          />
        </div>
        <button
          className='btn btn-primary'
          style={{ marginTop: 'auto' }}
          onClick={ () => props.onChangeOverallAssessmentHOC( true, 'showPreviewModal' ) }>
          <Plagiarism /> { getTranslation( language, 'Preview' ) }
        </button>
      </div>
      <div className='row mt-4 mx-1'>
        {
          props.selectedFile && (
            <div className='col-lg-8'>
              <div className='react-pdf-viewer__filename'>
                <FaFilePdf />{ props.selectedFilename || 'No file name' }
                <Clear style={{ cursor: 'pointer', marginLeft: 'auto', color: 'grey' }} onClick={ () => props.onChangeOverallAssessmentHOC( '', 'selectedFile' ) } />
              </div>
              <Document file={ props.selectedFile } onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={ pageNumber } />
              </Document>
              <div className='react-pdf-viewer__navigation'>
                <button
                  style={{ width: 100 }}
                  className='btn btn-outline-secondary mr-2'
                  disabled={ pageNumber === 1 }
                  onClick={ () => setPageNumber( pageNumber - 1 ) }>
                  { getTranslation( language, 'Previous' ) }
                </button>
                <b className='m-2'>{ pageNumber }</b> / <b className='m-2'>{ numPages }</b>
                <button
                  style={{ width: 100 }}
                  className='btn btn-outline-secondary ml-2'
                  disabled={ pageNumber === numPages }
                  onClick={ () => setPageNumber( pageNumber + 1 ) }>
                  { getTranslation( language, 'Next' ) }
                </button>
              </div>
            </div>
          )
        }
        <div className={ `${ props.selectedFile ? 'col-lg-4' : 'col-lg-12' } site-audit__audit-cont` }>
          { renderCriteriaColumn() }
        </div>
      </div>
      {
        props.showPreviewModal && <PreviewModal
          kpi={ props.kpi }
          projectDetail={ props.projectDetail }
          activity_reports={ props.activity_reports }
          project_assessment={ props.project_assessment }
          summary_year_options={ props.summary_year_options }
          activity_reports_summary={ props.activity_reports_summary }
          onLoadProjectAssessment={ props.onLoadProjectAssessment }

          getKPIs={ props.getKPIs }
          getActivityReports={ props.getActivityReports }
          getProjectAssessment={ props.getProjectAssessment }
          onChangeHOC={ props.onChangeOverallAssessmentHOC }
          setOpen={ val => props.onChangeOverallAssessmentHOC( val, 'showPreviewModal' ) }  
        />
      }
      {
        props.onLoadoverallAssessment && <LoadingModal />
      }
    </>
  )
}

export default OverallAssessmentHOC( ViewAssessment );
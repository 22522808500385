import React, { useEffect, useState } from "react";

import Tab from "components/Tab";
import Achievement from "./Achievement";
import MainKPI from "./MainKPI";

const KPI = (props) => {
  const [selectedTab, setSelectedTab] = useState("Main KPI");

  return (
    <>
      <Tab
        selections={["Main KPI", "Other Achievement"]}
        selectedItem={selectedTab}
        onSelect={setSelectedTab}
        unableCaching={true}
      />
      <hr />
      {selectedTab === "Main KPI" && <MainKPI {...props} />}
      {selectedTab === "Other Achievement" && <Achievement {...props} />}
    </>
  );
};

export default KPI;

import React from 'react'
import localStorage from 'localStorage'
import { PlaylistRemove } from '@mui/icons-material'

import { getTranslation } from 'utils/renderLanguage'
import './index.scss'

const language = localStorage.getItem( 'english' )

const EmptyState = ({
  title,
  description
}) => {

  return(
    <div className='at-empty-state-cont'>
      <div className='at-empty-state__icon'>
        <PlaylistRemove />
      </div>
      <p className='title'>{ getTranslation( language, title ) }</p>
      {
        description && <p className='description'>{ getTranslation( language, description ) }</p>
      }
    </div>
  )
}

export default EmptyState;
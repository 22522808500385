import React, { useEffect } from 'react'
import _ from 'lodash'
import { useOutletContext } from "react-router-dom";
import { ScienceOutlined, InfoTwoTone } from '@mui/icons-material'

import SearchForm from 'components/Search';
import CardList from 'components/Card/CardList'
import LoadingModal from 'components/LoadingModal'
import ModuleHeader from 'components/Card/ModuleHeader'

import { columns } from './assets';
import ProjectManagementHOC from './actions'
import { getTranslation } from 'utils/renderLanguage'
import './index.scss'

const ProjectManagement = props => {
  
  const outletContext = useOutletContext()
  const { language, user, onNavigate } = outletContext

  useEffect( () => {
    props.getProjects( user.uid )
  }, [])

  return(
    <>
      <ModuleHeader 
        title={ getTranslation( language, 'Project Management' ) }
        description={ getTranslation( language, 'This module use to show all the project in the system and allow Project Leader to submit document and progress.' ) }
        actionButton={[]}
        renderIcon={ () => <ScienceOutlined /> }
      />
      {/* <InfoCard
        cardList={ cardList }
        dataValue={ props.dataValue }
      /> */}
      <SearchForm
        { ...props }
        data={ props.projectList || [] }
        onChangeSeachParam={val => props.onChangeProjectManagementHOC(val, 'searchProjectList')}
        onChangeHOC={props.onChangeProjectManagementHOC} />
      <CardList
        columns={ columns({
          selected_year: props.selected_year
        }) }
        data={ props.searchProjectList || [] }
        actions={[{
          label: (
            <>
              <InfoTwoTone style={{ marginRight: 5 }}/>
              { getTranslation( language, 'View' ) }
            </>
          ),
          onClick: rowData => onNavigate( `/dashboard/project-management/${ rowData.project_id }` )
        }]}
      />
      {
        props.onLoadProjectManagement && <LoadingModal />
      }
    </>
  )
}

export default ProjectManagementHOC( ProjectManagement );
import React from 'react'
import localStorage from 'localStorage'
import { useNavigate } from "react-router-dom"
import { KeyboardDoubleArrowRight } from '@mui/icons-material'

import LanguageToggle from 'components/Input/langguageToggle'

import { getTranslation } from 'utils/renderLanguage'

import './index.scss'

const language = localStorage.getItem( 'english' )

const LandingPage = () => {

  const navigate = useNavigate()

  return(
    <div className='landing-page'>
      <LanguageToggle className={ 'at-login__toggle' } style={{ right: 20 }}/>
      <div className='landing-page-header'>
        <h3>{ getTranslation( language, 'SYSTEM e-Pantau' ) }</h3>
        <p>{ getTranslation( language, 'Brief information on the e-Pantau system' ) }</p>
      </div>
      <div className='landing-page-pengenalan'>
        <h3>{ getTranslation( language, 'Introduction' ) }</h3>
        <p>{ getTranslation( language, 'e-Pantau is a web-based system that aims to monitor the performance of program implementation by the project team (implementing agency). The e-Pantau system is a data bank that contains information on the entire program such as performance throughout the program, program progress reports and program evaluation.' ) }</p>
      </div>
      <div className='landing-page-tujuan'>
        <h3>{ getTranslation( language, 'PURPOSE of e-Pantau' ) }</h3>
        <ul>
          <li>{ getTranslation( language, 'Giving key information to stake holders/agencies, especially in terms of the overall achievement of the implemented program.' ) }</li>
          <li>{ getTranslation( language, 'Facilitating the process of program monitoring and evaluation through systematic and structured information storage methods.' ) }</li>
          <li>{ getTranslation( language, 'Simplifying the process of compiling reports in the form of data for each phase implemented in the program' ) }</li>
        </ul>
      </div>
      <button
        onClick={ () => navigate( '/dashboard' ) }
        className='landing-page-to-dashbaord'>
        <KeyboardDoubleArrowRight />
        { getTranslation( language, 'To Dashboard' ) }
      </button>
    </div>
  )
}

export default LandingPage;
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import localStorage from "localStorage";
import { ImFilePdf } from "react-icons/im";
import { Form, Modal, Button } from "react-bootstrap";
import { Select, MenuItem } from "@mui/material";
import { DeleteForeverRounded } from "@mui/icons-material";
import CustomSnackbar from "components/Snackbar";

import { getTranslation } from "utils/renderLanguage";
import moment from "moment";

const language = localStorage.getItem("english");

const fieldData = [
  { label: "Title", value: "title", type: "text", required: true },
  {
    label: "Description",
    value: "description",
    type: "textarea",
    required: true,
  },
  {
    label: "Submission Date",
    value: "submited_date",
    type: "date",
    required: true,
  },
  { label: "", value: "", type: "" },
  {
    label: "Actual Start Date",
    value: "milestone_actual_start_date",
    type: "date",
    required: true,
  },
  {
    label: "Actual End Date",
    value: "milestone_actual_end_date",
    type: "date",
    required: true,
  },
  { label: "Video Folder Link", value: "video_folder_link", type: "text" },
  {
    label: "Publication Folder Link",
    value: "publication_folder_link",
    type: "text",
  },
  { label: "Image Folder Link", value: "image_folder_link", type: "text" },
  { label: "Other Folder Link", value: "others_folder_link", type: "text" },
  {
    label: "Estimate Completion(%)",
    value: "completion_percentage",
    type: "number",
    required: true,
  },
  {
    label: "Activity Report",
    value: "activity_report_file",
    type: "file",
    required: true,
  },
];

const milestoneReportTemplate = {
  project_id: "",
  next_progress: {},
  current_stage: "",
  current_progress: {},
  milestone_update: {},
};

const ActivityReportForm = (props) => {
  const [activityReport, setActivityReport] = useState({
    // submited_date: Moment().format( 'DD MMM YYYY' ),
    submited_date: "",
    title: "",
    description: "",
    activity_report_file: "",
    completion_percentage: "",
    video_folder_link: "",
    publication_folder_link: "",
    image_folder_link: "",
    others_folder_link: "",
    milestone_actual_start_date: "",
    milestone_actual_end_date: "",
    project_id: props.projectDetail.project_id,
    status: "Submitted",
  });
  const [milestone_report, setMilestoneReport] = useState([{}]);
  const [utilization, setUtilization] = useState({});
  const [error, setError] = useState({});

  const [snackBarState, setSnackBarState] = useState({
    showSnackbar: false,
    snackbarMessage: "",
    snackbarType: "",
    onCloseSnackbar: () => {},
  });

  useEffect(() => {
    if (props.selectedActivityReport && props.mode === "update") {
      setActivityReport(props.selectedActivityReport);
      const tempMilestoneReport =
        props.selectedActivityReport.updated_miletones?.map((item) => {
          const milestone = _.find(props.stage_and_milestone, {
            stage_index: item.stage_index,
            milestone_index: item.milestone_index,
          });
          return {
            ...milestone,
            report_index: props.stage_and_milestone.findIndex(
              (stage) =>
                stage.stage_index === item.stage_index &&
                stage.milestone_index === item.milestone_index
            ),
          };
        });
      setMilestoneReport(tempMilestoneReport || []);
    }
  }, []);

  const handleSubmit = () => {
    const missingFields = [];

    // Validation logic for required fields
    if (
      !activityReport.activity_report_file_url &&
      !activityReport.activity_report_file
    ) {
      missingFields.push("Activity report file is missing.");
    }
    if (!milestone_report || milestone_report.length === 0) {
      missingFields.push("Milestone report is required.");
    }
    if (
      _.values(milestone_report).some((item) => item.milstone_actual_kpi === "")
    ) {
      missingFields.push("Milestone actual KPI is missing in some milestones.");
    }
    if (activityReport.title === "") {
      missingFields.push("Title is required.");
    }
    if (activityReport.description === "") {
      missingFields.push("Description is required.");
    }
    if (activityReport.milestone_actual_start_date === "") {
      missingFields.push("Milestone actual start date is missing.");
    }
    if (activityReport.milestone_actual_end_date === "") {
      missingFields.push("Milestone actual end date is missing.");
    }
    if (
      props.mode === "create" &&
      (!utilization.amount || utilization.amount === 0)
    ) {
      missingFields.push("Utilization amount is required.");
    }

    // Show CustomSnackbar for missing fields
    if (missingFields.length > 0) {
      setSnackBarState({
        showSnackbar: true,
        snackbarMessage: missingFields.join(" "),
        snackbarType: "error",
        onCloseSnackbar: () =>
          setSnackBarState({
            showSnackbar: false,
            snackbarMessage: "",
            snackbarType: "",
            onCloseSnackbar: () => {},
          }),
      });
      return;
    }

    // Proceed with the rest of the logic if all validations pass
    Promise.all([
      milestone_report.map(async (report, index) => {
        await setTimeout(() => {
          props.updateProjectPerformance({
            project_id: props.projectDetail.project_id,
            current_stage:
              props.stage_and_milestone[report.report_index].stage_name,
            current_progress: {
              stage: props.stage_and_milestone[report.report_index].stage_index,
              milestone:
                props.stage_and_milestone[report.report_index].milestone_index,
            },
            next_progress: {},
            milestone_update: {
              milestone_actual_start_date:
                activityReport.milestone_actual_start_date ||
                Moment().format("YYYY-MM-DD"),
              milestone_actual_end_date:
                activityReport.milestone_actual_end_date ||
                Moment().format("YYYY-MM-DD"),
              milstone_actual_kpi: report.milstone_actual_kpi,
              milestone_end_date: Moment(report.milestone_end_date).format(
                "YYYY-MM-DD"
              ),
              milestone_start_date: Moment(report.milestone_start_date).format(
                "YYYY-MM-DD"
              ),
              milestone_fund_utilization: report.milestone_fund_utilization,
              milestone_status: report.milestone_status,
              milestone_title: report.milestone_title,
              milstone_kpi: report.milstone_kpi,
              updated_at: report.updated_at,
            },
          });
        }, (index + 1) * 500);
      }),
      setTimeout(() => onCreateUtilization(), 2000),
    ]).then(() => {
      let temp = _.cloneDeep(activityReport);
      if (props.mode === "create") {
        setTimeout(
          props.createActivityReport({
            ...temp,
            updated_miletones: milestone_report?.map((item) => ({
              stage_index: item.stage_index,
              milestone_index: item.milestone_index,
            })),
            status: "Submitted",
          }),
          4000
        );
      } else {
        setTimeout(
          props.updateActivityReport({
            ...temp,
            updated_miletones: milestone_report?.map((item) => ({
              stage_index: item.stage_index,
              milestone_index: item.milestone_index,
            })),
            status: "Submitted",
          }),
          4000
        );
      }
    });
  };

  const onCreateUtilization = () => {
    if (utilization.amount > 0) {
      props.createUtilization({
        utilization_amount: utilization.amount,
        utilization_percentage:
          (utilization.amount / props.projectDetail.total_grant) * 100,
        project_completion: props.stage_and_milestone.reduce(
          (acc, milestone) =>
            acc + parseInt(milestone.milstone_actual_kpi || 0),
          0
        ),
        utilization_file: utilization.utilization_file,
        utilication_date: Moment().format("DD MMM YYYY"),
        project_id: props.projectDetail.project_id,
      });
    }
  };

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(activityReport);
    temp[context] = val;
    setActivityReport(temp);
  };

  const onUploadFile = (event, context) => {
    if (event.target.files && event.target.files.length > 0) {
      let files = event.target.files;
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = {
            snapshot: e.target.result,
            snapshot_file_name: files[key]
              ? files[key].name
              : "activity_report",
          };
          onChangeField(tmp, context);
        };
        reader.readAsDataURL(event.target.files[key]);
      });
    }
  };

  const onChangeMilestone = (val, context, index) => {
    let temp = _.cloneDeep(milestone_report);
    temp[index][context] = val;
    if (context === "report_index") {
      temp[index] = {
        report_index: val,
        ...props.stage_and_milestone[val],
      };
    }
    setMilestoneReport(temp);
  };

  const onUploadUtilizationFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let files = event.target.files;
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = {
            snapshot: e.target.result,
            snapshot_file_name: files[key] ? files[key].name : "utilization",
          };
          setUtilization({ ...utilization, utilization_file: tmp });
        };
        reader.readAsDataURL(event.target.files[key]);
      });
    }
  };

  return (
    <Modal show={true} onHide={() => props.setOpen(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="row">
        {fieldData.map((item, index) => {
          if (item.type === "select") {
            return (
              <Form.Group key={index} className="mt-3 col-lg-6">
                <Form.Label>
                  {getTranslation(language, item.label)}
                  {item.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <Form.Select
                  aria-label={getTranslation(language, item.label)}
                  value={activityReport[item.value]}
                  onChange={(e) => onChangeField(e.target.value, item.value)}
                >
                  <option hidden value={""}>
                    Select
                  </option>
                  {item.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            );
          } else if (item.type === "file") {
            return (
              <Form.Group key={index} className="mt-3 col-lg-6">
                <Form.Label>
                  {getTranslation(language, item.label)}
                  {item.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                {(activityReport.activity_report_file_url ||
                  activityReport[item.value]) && (
                  <div
                    className="project-detail__detail-cont d-flex align-items-center"
                    style={{ padding: "10px 20px" }}
                  >
                    <ImFilePdf
                      style={{ width: 40, height: 40, color: "red" }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <label>File Name</label>
                      <p className="m-0">
                        {activityReport.activity_report_file_url
                          ? activityReport.activity_report_file_name
                          : activityReport[item.value].snapshot_file_name}
                      </p>
                    </div>
                    <button
                      className={"btn btn-danger ml-auto"}
                      onClick={() => {
                        if (activityReport.activity_report_file_url) {
                          onChangeField("", "activity_report_file_url");
                        } else {
                          onChangeField("", "activity_report_file");
                        }
                      }}
                    >
                      <DeleteForeverRounded />
                    </button>
                  </div>
                )}
                {!activityReport.activity_report_file_url &&
                  !activityReport[item.value] && (
                    <Form.Control
                      type={"file"}
                      style={{ height: "fit-content" }}
                      accept={"application/pdf"}
                      onChange={(event) => onUploadFile(event, item.value)}
                    />
                  )}
                {error[item.value] && (
                  <Form.Text>{error[item.value]}</Form.Text>
                )}
              </Form.Group>
            );
          } else if (!item.label && !item.value && !item.type) {
            return (
              <div key={index} className="col-lg-6">
                <hr style={{ display: "none" }} />
              </div>
            );
          } else {
            return (
              <Form.Group key={index} className="mt-3 col-lg-6">
                <Form.Label>
                  {getTranslation(language, item.label)}
                  {item.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <Form.Control
                  type={item.type}
                  placeholder={getTranslation(language, item.label)}
                  disabled={item.disabled}
                  value={activityReport[item.value]}
                  onChange={(e) => onChangeField(e.target.value, item.value)}
                />
                {error[item.value] && (
                  <Form.Text>{error[item.value]}</Form.Text>
                )}
              </Form.Group>
            );
          }
        })}
        <Form.Group className="mt-3 col-lg-6">
          <Form.Label>
            {getTranslation(language, "Fund Utilization")}%
            <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type={"number"}
            placeholder={getTranslation(language, "Fund Utilization")}
            value={utilization.amount}
            onChange={(e) =>
              setUtilization({ ...utilization, amount: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mt-3 col-lg-6">
          <Form.Label>
            {getTranslation(language, "Support Document")}
          </Form.Label>
          {(utilization.utilization_file_url ||
            utilization.utilization_file?.snapshot) && (
            <div
              className="project-detail__detail-cont d-flex align-items-center"
              style={{ padding: "10px 20px" }}
            >
              <ImFilePdf style={{ width: 40, height: 40, color: "red" }} />
              <div style={{ marginLeft: 10 }}>
                <label>File Name</label>
                <p className="m-0">
                  {utilization.utilization_file_url
                    ? utilization.utilization_file_name
                    : utilization.utilization_file?.snapshot_file_name}
                </p>
              </div>
              <button
                className={"btn btn-danger ml-auto"}
                onClick={() => {
                  if (utilization.utilization_file_url) {
                    setUtilization({
                      ...utilization,
                      utilization_file_url: "",
                    });
                  } else {
                    setUtilization({ ...utilization, utilization_file: {} });
                  }
                }}
              >
                <DeleteForeverRounded />
              </button>
            </div>
          )}
          {!(
            utilization.utilization_file_url ||
            utilization.utilization_file?.snapshot
          ) && (
            <Form.Control
              type={"file"}
              style={{ height: "fit-content" }}
              accept={"application/pdf"}
              onChange={(event) => onUploadUtilizationFile(event)}
            />
          )}
        </Form.Group>
        <div className="col-12 mt-3">
          {milestone_report &&
            milestone_report?.map((item, reportIndex) => {
              return (
                <div
                  key={reportIndex}
                  className="project-detail__milestone-cont mb-2"
                >
                  <div className="row">
                    <Form.Group className="mt-3 col-lg-6">
                      <Form.Label>
                        {getTranslation(language, "Milestone Report")}
                      </Form.Label>
                      <Form.Select
                        aria-label={"report_index"}
                        value={item.report_index || []}
                        onChange={(e) =>
                          onChangeMilestone(
                            e.target.value,
                            "report_index",
                            reportIndex
                          )
                        }
                      >
                        <option hidden value={""}></option>
                        {props.stage_and_milestone?.map((option, index) => (
                          <option key={index} value={index}>
                            {option?.milestone_title} ({option?.stage_name})
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mt-3 col-lg-6">
                      <Form.Label>
                        {getTranslation(language, "KPI")}%
                      </Form.Label>
                      <Form.Control
                        type={"number"}
                        placeholder={getTranslation(language, "KPI")}
                        value={item.milstone_actual_kpi}
                        onChange={(e) =>
                          onChangeMilestone(
                            e.target.value,
                            "milstone_actual_kpi",
                            reportIndex
                          )
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mt-3 col-lg-12">
                      <button
                        className="btn btn-danger"
                        style={{ width: 300 }}
                        // disabled={ props.this_month_submitted }
                        onClick={() => {
                          let temp = _.cloneDeep(milestone_report);
                          temp.splice(reportIndex, 1);
                          setMilestoneReport(temp);
                        }}
                      >
                        {getTranslation(language, "Delete")}
                      </button>
                    </Form.Group>
                  </div>
                </div>
              );
            })}
          <button
            fullWidth
            className="btn btn-primary"
            onClick={() => {
              let temp = _.cloneDeep(milestone_report);
              temp.push(milestoneReportTemplate);
              setMilestoneReport(temp);
            }}
          >
            {getTranslation(language, "Add Milestone Report")}
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          {getTranslation(language, "Close")}
        </Button>
        {props.mode === "create" && (
          <Button
            variant="secondary"
            onClick={() =>
              props.createActivityReport({
                ...activityReport,
                status: "Draft",
              })
            }
          >
            {getTranslation(language, "Draft")}
          </Button>
        )}
        <Button variant="primary" onClick={handleSubmit}>
          {getTranslation(language, "Submit")}
        </Button>
      </Modal.Footer>
      <CustomSnackbar
        open={snackBarState.showSnackbar}
        message={snackBarState.snackbarMessage}
        type={snackBarState.snackbarType}
        onClick={snackBarState.onCloseSnackbar}
      />
    </Modal>
  );
};

export default ActivityReportForm;

import React, { useEffect } from 'react'
import { EditOutlined, DeleteForeverRounded, PictureAsPdfRounded } from '@mui/icons-material'

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import ConfirmationModal from 'components/Card/Confirmation'

import EditAchievement from './form'
import CreateAchievement from './form'

import KIPHOC from './actions'
import { badgeStatus } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'

const columnData = ( language ) => [
  { header: getTranslation( language, 'No.' ), renderColumn: ( data, index ) => index + 1, containerStyle: { width: '30%' } },
  { header: getTranslation( language, 'Achievement' ), accessor: 'achievement_title', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Description' ), accessor: 'achievement_description', containerStyle: { width: '150%' },
    renderColumn: rowData => <p style={{ textAlign: 'justify' }}>{ rowData.achievement_description }</p>
  },
  { header: getTranslation( language, 'Support Document' ), containerStyle: { width: '50%' },
    renderColumn: rowData => {
      if( rowData.achievement_document_url ){
        return(
          <button className='btn' onClick={ () => window.open( rowData.achievement_document_url, '_blank' ) }>
            <PictureAsPdfRounded style={{ color: '#cb1515' }}/>
          </button>
        )
      } else {
        return '-'
      }
    }
  },
  { header: getTranslation( language, 'Submission Date' ), accessor: 'achievement_submission_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Notes' ), accessor: 'achievement_submission_note', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Status' ), containerStyle: { width: '50%' },
    renderColumn: rowData => <p className='badge__status' style={ badgeStatus( rowData.achievement_status ) }>{ rowData.achievement_status }</p>
  },
]

const Achievement = props => {

  useEffect( () => {
    props.getAchievements( props.projectDetail.achievement_indication )
  }, [ props.projectDetail ])

  return(
    <>
      <div className='mx-4'>
        <div className={ 'd-flex align-items-end mb-2' }>
          <h2 style={{ fontSize: 20, fontWeight: 500 }}>{ getTranslation( props.language, 'Project Achievement' ) }</h2>
        </div>
          <PaginatedTable
            rowData={ props.achievements }
            columnData={ columnData( props.language ) }
            actionsColumn={[{
              name: 'Approve',
              className: 'btn btn-success',
              onShow: rowData => rowData.achievement_status === 'In Review',
              onClick: rowData => Promise.all([
                props.onChangeAchievementHOC( rowData, 'selectedAchievement' )
              ]).then( () => {
                props.onChangeAchievementHOC( true, 'showApproveConfirmation' )
              })
            }, {
              name: 'Edit',
              onShow: rowData => rowData.achievement_status === 'Pending',
              renderActionIcon: <EditOutlined />,
              onClick: rowData => Promise.all([
                props.onChangeAchievementHOC( rowData, 'selectedAchievement' )
              ]).then( () => {
                props.onChangeAchievementHOC( true, 'showEditModal' )
              })
            }, {
              name: 'Delete',
              style: { background: '#cb1515' },
              onShow: rowData => rowData.achievement_status === 'Pending',
              renderActionIcon: <DeleteForeverRounded />,
              onClick: rowData => Promise.all([
                props.onChangeAchievementHOC( rowData, 'selectedAchievement' )
              ]).then( () => {
                props.onChangeAchievementHOC( true, 'showDeleteConfirmation' )
              })
            }]}
          />
      </div>
      {
        props.showCreateModal && <CreateAchievement
          mode={ 'create' }
          title={ getTranslation( props.language, 'Create New Achievement' ) }
          language={ props.language }
          projectDetail={ props.projectDetail }
          createNewAchievement={ props.createNewAchievement }
          setOpen={ val => props.onChangeAchievementHOC( val, 'showCreateModal' ) }  
        />
      }
      {
        props.showEditModal && <EditAchievement
          mode={ 'update' }
          title={ getTranslation( props.language, 'Edit Achievement' ) }
          language={ props.language }
          updateAchievement={ props.updateAchievement }
          selectedAchievement={ props.selectedAchievement }
          projectDetail={ props.projectDetail }
          setOpen={ val => props.onChangeAchievementHOC( val, 'showEditModal' ) }  
        />
      }
      {
        props.showApproveConfirmation && <ConfirmationModal
          isOpen={ true }
          onClick={ () => props.updateAchievement({ ...props.selectedAchievement, achievement_status: 'Complete' }) }
          confirmationText={
            <>
              You are going to approve the selected achievement from the project?
              <br/>
              Make sure that you have reviewed the submitted document.
              <br/><br/>
              <b className='text-danger'>This action is non-reversible.</b>
            </>
          }
          setOpen={ val => props.onChangeAchievementHOC( val, 'showApproveConfirmation' ) }
        />
      }
      {
        props.showDeleteConfirmation && <ConfirmationModal
          isOpen={ true }
          onClick={ () => props.deleteAchievement( props.selectedAchievement.achievement_id ) }
          confirmationText={
            <>
              { getTranslation( props.language, 'You are going to remove the selected achievement from the project?' ) }
              <br/><br/>
              <b className='text-danger'>{ getTranslation( props.language, 'This action is non-reversible.' ) }</b>
            </>
          }
          setOpen={ val => props.onChangeAchievementHOC( val, 'showDeleteConfirmation' ) }
        />
      }
      { props.onLoadAchivement && <LoadingModal /> }
    </>
  )
}

export default KIPHOC( Achievement )
import React, { Component } from 'react'
import localStorage from 'localStorage'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import CustomSnackbar from 'components/Snackbar';

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      showForgetPassword: false,
      loginCredential: {
        email: '',
        password: '',
      },
      recoverEmail: ''
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeLoginHOC = ( val, context ) => this.setState({ [ context ]: val })

    userLogin = () => {
      this.load( true )
      const auth = getAuth();
      let temp = this.state.loginCredential
      signInWithEmailAndPassword( auth, temp.email, temp.password)
        .then((userCredential) => {
          this.getUser( userCredential.user.uid )
        })
        .catch((error) => {
          this.requestError( error.message )
          this.load( false )
        });
    }

    recoverPassword = () => {
      this.load( true )
      const auth = getAuth();
      sendPasswordResetEmail( auth, this.state.recoverEmail ).then(() => {
        this.load( false )
        window.alert( 'Please check your email to recover your password.' )
        this.setState({ showForgetPassword: false })
      }).catch( error  => {
        this.load( false )
        this.requestError( error.message )
      })
    }

    getUser = ( uid ) => Get(
      `/getUser?uid=${ uid }`,
      this.getUserSuccess,
      this.getUserError,
      this.load
    )
    getUserSuccess = payload => {
      this.requestSuccess( 'Login Success.' )
      localStorage.setItem( 'userLogin', JSON.stringify( payload.data ) )
    }
    getUserError = error => this.requestError( error.message )
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            { ... this.state }
            onLoginLoad={ this.state.loading }

            onChangeLoginHOC={ this.onChangeLoginHOC }
            recoverPassword={ this.recoverPassword }
            userLogin={ this.userLogin }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
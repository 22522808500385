import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import DatePicker  from 'react-datepicker'
import { Form, Modal, Button } from "react-bootstrap"
import { Select, Box, Chip, MenuItem } from '@mui/material'

import { getTranslation } from 'utils/renderLanguage'
import { statusOptions, stateOptions } from 'utils/dictionary'

const fieldList = ( userDictionary, language ) => [
  { label: getTranslation( language, 'Project Name' ), value: 'project_name', type: 'text' },
  { label: getTranslation( language, 'Description' ), value: 'project_description', type: 'textarea' },
  { label: getTranslation( language, 'Implementing Agency' ), value: 'implementing_agency', type: 'text' },
  { label: getTranslation( language, 'Assessor' ), value: 'assessor', type: 'multi-select', options: userDictionary.assessor },
  { label: getTranslation( language, 'Project Leader' ), value: 'project_leader', type: 'multi-select', options: userDictionary.project_leader },
  { label: getTranslation( language, 'Secretariat' ), value: 'secretariat', type: 'multi-select', options: userDictionary.secretariat },
  { label: getTranslation( language, 'Start Year' ), value: 'start_year', type: 'date' },
  { label: getTranslation( language, 'Funder' ), value: 'funder', type: 'text' },
  { label: getTranslation( language, 'Total Grant' ), value: 'total_grant', type: 'text' },
  { label: getTranslation( language, 'State' ), value: 'state', type: 'select', options: stateOptions },
  { label: getTranslation( language, 'Status' ), value: 'status', type: 'select', options: statusOptions },
]

const ProjectForm = props => {

  const fieldData = fieldList( props.userDictionary, props.language )
  const [ projectDetails, setProjectDetails ] = useState({
    project_name: '',
    project_description: '',
    implementing_agency: '',
    assessor: '',
    secretariat: '',
    project_leader: '',
    current_stage: 'Not Started',
    start_year: '',
    funder: '',
    state: '',
    status: '',
    current_progress: {
      stage: 0,
      milestone: 0
    },
    total_grant: '',
    activity_report: [],
    kpi_indication: [],
    achievement_indication: [],
    disbursement_progress: [],
    utilization_progress: [],
    stage_and_milestone: [],
    assessment: [],
    site_audit: [],
    overall_assessment: []
  })
  const [error, setError] = useState({})

  useEffect( () => {
    if( props.selectedProject && props.mode === 'update' ){
      setProjectDetails( props.selectedProject )
    }

    if( props.mode === 'create' && props.currentUser.role === 'Secretariat' ){
      onChangeField( [ props.currentUser.uid ], 'secretariat' )
    }
  }, [])

  const handleSubmit = () => Promise.all([
    fieldData.map( item => {
      if( projectDetails[ item.value ] === '' ){
        setError({ [ item.value ]: `${ item.label } should not be empty.` })
      }
    })
  ]).then( () => {
    if( !_.values( fieldData ).some( x => !projectDetails[ x.value ] || projectDetails[ x.value ] === '' ) ){
      if( props.mode === 'create' )
        props.createNewProject(projectDetails)
      else 
        props.updateProject( projectDetails )
    }
  })

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( projectDetails )
    temp[ context ] = val
    setProjectDetails( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body className='row'>
        {
          fieldData.map(( item, index ) => {
            if( item.type === 'select' ){
              return(
                <Form.Group key={ index } className="mt-3 col-lg-6">
                  <Form.Label>{ item.label }</Form.Label>
                  <Form.Select 
                    aria-label={ item.label } 
                    value={ projectDetails[ item.value ] }
                    onChange={ e => onChangeField( e.target.value, item.value )}>
                  <option hidden value={ '' }>Select</option>
                  {
                    item.options.map(( option, index ) => (
                      <option key={ index } value={ option.value }>{ option.label }</option>
                    ))
                  }
                  </Form.Select>
                </Form.Group>
              )
            } else if( item.type === 'multi-select' ){
              return(
                <Form.Group key={ index } className="mt-3 col-lg-6">
                  <Form.Label>{ item.label }</Form.Label>
                  <Select
                    fullWidth
                    multiple
                    style={{ borderRadius: `0.375rem`, borderColor: '#ced4da' }}
                    value={ projectDetails[ item.value ] || [] }
                    onChange={ e => onChangeField( e.target.value, item.value ) }
                    renderValue={ ( selected ) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        { 
                          selected.map(( value, index ) => {
                            let temp = _.find( item.options, { value: value })
                            return(
                              <Chip color="primary" key={ index } label={ temp.label } />
                            )
                          })
                        }
                      </Box>
                    )}
                  >
                    {
                      item.options.map(( option, index ) => (
                      <MenuItem key={ index } value={ option.value } >{ option.label }</MenuItem>
                    ))}
                  </Select>
                </Form.Group>
              )
            } else if( item.type === 'date' ) {
              return(
                <Form.Group key={ index } className='mt-3 col-lg-6'>
                  <Form.Label>{ item.label }</Form.Label>
                  <DatePicker 
                    className='form-date-picker'
                    dateFormat="MMM yyyy"
                    showMonthYearPicker
                    selected={ typeof(  projectDetails[ item.value ] ) === 'string'
                      ? (  projectDetails[ item.value ]
                        ? new Date(  projectDetails[ item.value ] )
                        : ''
                      ) :  projectDetails[ item.value ]
                    }
                    onChange={ val => onChangeField( val, item.value ) } />
                </Form.Group>
              )
            } else {
              return(
                <Form.Group key={ index } className='mt-3 col-lg-6'>
                  <Form.Label>{ item.label }</Form.Label>
                  <Form.Control
                    type={ item.type }
                    placeholder={ item.label }
                    value={ projectDetails[ item.value ] }
                    onChange={ e => onChangeField( e.target.value, item.value ) }
                  />
                  { error[ item.value ] && <Form.Text>{ error[ item.value ] }</Form.Text>}
                </Form.Group>
              )
            }
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          { getTranslation( props.language, 'Close' ) }
        </Button>
        <Button disabled={ _.values( fieldData ).some( x => !projectDetails[ x.value ] || projectDetails[ x.value ] === '' ) } variant="primary" onClick={ handleSubmit }>
          { getTranslation( props.language, 'Submit' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProjectForm;
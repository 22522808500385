import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      dataValue: {
        start_year: 0,
        submission_date: 0,
        current_stage: 0,
        current_milestone: 0,
      },
      projectDetail: {
        project_name: '',
        project_description: '',
        implementing_agency: '',
        assessor: '',
        project_leader: '',
        current_stage: '',
        start_year: '',
        funder: '',
        state: '',
        status: '',
        activity_report: [],
        kpi_indication: [],
        achievement_indication: [],
        disbursement_progress: [],
        utilization_progress: [],
        stage_and_milestone: [],
        assessment: []
      },
      disbursementList: [],
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectDetailHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjectDetail = id => Get(
      `/project-getProject?project_id=${ id }`,
      this.getProjectDetailSuccess,
      this.getProjectDetailError,
      this.load
    ) 
    getProjectDetailSuccess = payload => this.setState({ projectDetail: payload.data })
    getProjectDetailError = error => this.requestError( error )

    getDisbursementDictionary = disbursements => Get(
      `/financial-getDisbursementsDictionary?disbursements=${  JSON.stringify( disbursements )  }`,
      this.getDisbursementDictionarySuccess,
      this.getDisbursementDictionaryError,
      this.load
    )
    getDisbursementDictionarySuccess = payload => this.setState({ disbursementList: payload.data.disbursement })
    getDisbursementDictionaryError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectDetails={ this.state.loading }

            getProjectDetail={ this.getProjectDetail }
            getDisbursementDictionary={ this.getDisbursementDictionary }
            onChangeProjectDetailHOC={ this.onChangeProjectDetailHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC
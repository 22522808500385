import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import { Tooltip } from '@mui/material';
import { Add, EditOutlined, DeleteForeverRounded } from '@mui/icons-material'

import StageMilestoneForm from './forms';
import LoadingModal from 'components/LoadingModal';
import PaginatedTable from 'components/PaginatedTable';
import DeleteConfirmation from 'components/Card/Confirmation'

import { milestonStatus } from "assets/ProjectManagement";
import { getTranslation } from 'utils/renderLanguage'
import { renderBackground } from 'utils/stageStatus'
import StageMilestoneHOC from './actions';
import { columnData } from './assets'

const StageAndMilestone = ({ language, ...props }) => {

  const [ projectDetail, setProjectDetail ] = useState( props.projectDetail )
  const [ milestoneList, setMilestoneList ] = useState([])

  useEffect( () => {
    setProjectDetail( props.projectDetail )
    props.getDisbursementDictionary( props.projectDetail.disbursement_progress )

    let temp = []
    props.projectDetail?.stage_and_milestone?.map((stage, stageIndex) => {
      stage.stage_milestone.map((milestone, milestoneIndex) => {
        temp.push({
          ...milestone,
          milestone_index: milestoneIndex,
          stage_index: stageIndex,
          stage_name: stage.stage_name,
          stage_status: stage.stage_status,
          stage_timeline: stage.stage_timeline
        })
      })
    })
    setMilestoneList( temp || [] )
  }, [ props.projectDetail ])

  const addNewStage = () => Promise.all([
    props.onChangeStageMilestone({
      stage_name: '',
      stage_status: 'Not Started',
      stage_milestone: [{
        milestone_title: '',
        milstone_kpi: '',
        milstone_actual_kpi: '',
        milestone_start_date: '',
        milestone_end_date: '',
        milestone_actual_start_date: '',
        milestone_actual_end_date: '',
        milestone_status: 'Not Started',
        milestone_fund_utilization: ''
      }]
    }, 'selectedStage' )
  ]).then( () => {
    props.onChangeStageMilestone( projectDetail.stage_and_milestone.length, 'selectedStageIndex' )
    props.onChangeStageMilestone( true, 'showEditModal' )
  })

  const onRemoveStage = () => {
    let temp = _.cloneDeep( projectDetail )
    temp.stage_and_milestone.splice( props.selectedStageIndex, 1 )
    setProjectDetail( temp )
  }

  return(
    <>
      <div className='d-flex justify-content-between mb-2'>
        <label className='at-project-mngmt__form-label'>
          { getTranslation( language, 'Stage & Milestone' ) }
          <div className='project-detail__milestone-item-content stage-milestone-overall' >
            <p style={{ fontWeight: 700, fontSize: 14, marginBottom: 0 }}>
              Overall KPI: {milestoneList.reduce((acc, milestone) => acc + parseFloat(milestone.milstone_actual_kpi || 0), 0)}% / 100%
            </p>
                </div>
        </label>
        <button
          style={{ width: 200 }}
          className='btn btn-primary'
          onClick={ () => addNewStage() }>
          <Add style={{ marginRight: 5 }}/>{ getTranslation( language, 'Add New Stage' ) }
        </button>
      </div>
      <div className='project-performance__timeline-cont'>
        <label>{ getTranslation( language, 'Project Timeline' ) }</label>
        <div className='d-flex mt-1'>
          {
            projectDetail.stage_and_milestone.map(( stage, index ) => {
              return(
                <Tooltip placement="top" title={ stage.stage_status || '-' }>
                  <span
                    key={ index }
                    style={{ background: renderBackground( stage.stage_status ) }}
                    className={ 'project-performance__timeline' }>
                    { stage.stage_name }
                    <p>{ stage.stage_timeline ? `Q${ Moment( stage.stage_timeline ).format( 'Q, yyyy' ) }` : '-' }</p>
                  </span>
                </Tooltip>
              )
            })
          }
        </div>
      </div>
      <PaginatedTable
        rowData={ projectDetail.stage_and_milestone || [] }
        columnData={ columnData( props.disbursementList ) }
        hidePagination={ true }
        actionsColumn={[{
          name: 'Edit',
          renderActionIcon: <EditOutlined />,
          onClick: ( rowData, index ) => Promise.all([
            props.onChangeStageMilestone( rowData, 'selectedStage' ),
          ]).then( () => {
            props.onChangeStageMilestone( index, 'selectedStageIndex' )
            props.onChangeStageMilestone( true, 'showEditModal' )
          })
        }, {
          name: 'Delete',
          style: { background: '#cb1515' },
          onShow: rowData => _.values( rowData.stage_milestone ).every( item => item.milestone_status === 'Not Started' ),
          renderActionIcon: <DeleteForeverRounded />,
          onClick: ( rowData, index ) => Promise.all([
            props.onChangeStageMilestone( rowData, 'selectedStage' )
          ]).then( () => {
            props.onChangeStageMilestone( index, 'selectedStageIndex' )
            props.onChangeStageMilestone( true, 'showDeleteModal' )
          })
        }]}
      />
      <div className='d-flex flex-column my-2' style={{ gap: 4 }}>
        {milestonStatus?.map((status, index) => (
          <div key={index} className='d-flex align-items-center'>
            <div style={{ background: status.color, width: 20, height: 20, borderRadius: '50%', marginRight: 5 }}></div>
            <p style={{marginBottom: 0, fontWeight: 500, fontSize: 14}}>{status.label}</p>
          </div>
        ))}
      </div>
      {
        props.projectDetail !== projectDetail && (
          <>
            <p className='text-danger mt-3 mb-0'>
              { getTranslation( language, 'You have unsaved changes.' ) }<br/>
              { getTranslation( language, 'Please click ' ) }<b>{ getTranslation( language, 'Update' ) }</b>{ getTranslation( language, ' to make the changes permanently. The changes will discard if you browse to other tab.' ) }
            </p>
            <button
              className='btn btn-primary mt-1'
              onClick={ () => props.updateProject( projectDetail ) }>
              { getTranslation( language, 'Update' ) }
            </button>
          </>
        )
      }
      {
        props.showEditModal && <StageMilestoneForm 
          language={ language }
          projectDetail={ projectDetail }
          selectedStage={ props.selectedStage }
          selectedStageIndex={ props.selectedStageIndex }
          disbursementList={ props.disbursementList }

          setProjectDetail={ setProjectDetail }
          setOpen={ val => props.onChangeStageMilestone( val, 'showEditModal' ) }
        />
      }
      {
        props.showDeleteModal && <DeleteConfirmation
        isOpen={ true }
        onClick={ () => Promise.all([
          onRemoveStage()
        ]).then( () => {
          props.onChangeStageMilestone( false, 'showDeleteModal' )
        })}
        confirmationText={
          <>
            { getTranslation( language, 'You are going to remove the selected stage ' ) }<b>({ props.selectedStage.stage_name })</b>{ getTranslation( language, ' from the project.' ) }
          </>
        }
        setOpen={ val => props.onChangeStageMilestone( val, 'showDeleteModal' ) }
      />
      }
      { props.onLoadStageMilestone && <LoadingModal /> }
    </>
  )
}

export default StageMilestoneHOC( StageAndMilestone );
import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      selectedStage: {},
      selectedStageIndex: '',

      showEditModal: false
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeStageMilestone = ( val, context ) => this.setState({ [ context ]: val })

    updateProject = dataToSubmit => Post(
      `/project-updateProjectStageMilestone`,
      dataToSubmit,
      this.updateProjectSuccess,
      this.updateProjectError,
      this.load
    )
    updateProjectSuccess = payload => {
      this.setState({ showEditModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
      this.requestSuccess( payload.data.message )
    }
    updateProjectError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadStageMilestone={ this.state.loading }

            updateProject={ this.updateProject }
            onChangeStageMilestone={ this.onChangeStageMilestone }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC
import localStorage from 'localStorage'
import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )

export const assessorAuditFieldData = [
  { label: `1. ${ getTranslation( language, 'Physical achievement of infrastructure reaches 50%-75% of project progress as reported.' ) }`, value: 'perkara_1', type: 'radio' },
  { label: `2. ${ getTranslation( language, 'Equipment and technology equipment is supplied and functional as specified asset and inventory records.' ) }`, value: 'perkara_2', type: 'radio' },
  { label: `3. ${ getTranslation( language, 'Infrastructure and equipment are provided to be used and benefited by the community.' ) }`, value: 'perkara_3', type: 'radio' },
  { label: `4. ${ getTranslation( language, 'The community gives feedback on project achievements and benefits are received.' ) }`, value: 'perkara_4', type: 'radio' },
  { label: `5. ${ getTranslation( language, 'Things to fix' ) }`, value: 'perkara_5', type: 'textarea' },
]

export const gradeOptions = [
  { id: 1, label: 'Unsatisfactory' },
  { id: 2, label: 'Satisfactory' },
  { id: 3, label: 'Good' },
  { id: 4, label: 'Very Good' },
  { id: 5, label: 'Excellent' },
]
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import localStorage from 'localStorage'
import { ImFilePdf } from 'react-icons/im';
import { Collapse, Tooltip } from '@mui/material';
import { OpenInNew, KeyboardArrowDown } from '@mui/icons-material'

import { 
  kpiFieldData,
  projectField,
  activityFieldData,
  milestoneFieldData,
  utilizationFieldData,
  disbursementFieldData
} from "assets/ProjectManagement";
import { getTranslation } from 'utils/renderLanguage'
import { renderBackground, badgeStatus } from 'utils/stageStatus'
import { thousandSeperatorFormatter } from 'utils/thousandSeparator';

const language = localStorage.getItem( 'english' )

const Overview = props => {

  const { projectDetail, disbursementList } = props

  const {
    kpi_indication_list,
    utilization_progress_list,
    disbursement_progress,
    disbursement_progress_list,
    stage_and_milestone,
    activity_report_list,
  } = projectDetail

  useEffect( () => {
    if( disbursement_progress && disbursement_progress.length > 0 ){
      props.getDisbursementDictionary( disbursement_progress )
    }
  }, [ projectDetail.project_name ])

  const [ showStageMilestone, setShowStageMilestone ] = useState( false )
  const [ showKPI, setShowKPI ] = useState( false )
  const [ showActivityReport, setShowActivityReport ] = useState( false )
  const [ showDisbursement, setShowDisbursement ] = useState( false )
  const [ showUtilization, setShowUtilization ] = useState( false )

  const renderStageMilestone = () => (
    <div className="project-detail__detail-cont" style={{ margin: 10 }}>
      <h2 className="content-title">
        { getTranslation( language, 'Stage & Milestone' ) }
        {
          stage_and_milestone && stage_and_milestone.length > 0 && (
            <Tooltip placement='top' title={ showStageMilestone ? 'Click to hide' : 'Click to expand' }>
              <button 
                className='button-expand'
                onClick={ () => setShowStageMilestone( !showStageMilestone )}>
                <KeyboardArrowDown style={{ transform: showStageMilestone && 'rotateZ(180deg)'}}/>
              </button>
            </Tooltip>
          )
        }
      </h2>
      {
        stage_and_milestone && stage_and_milestone.length > 0 && (
          <Collapse in={ showStageMilestone }>
            {
              stage_and_milestone.map(( item, index ) => {
                return(
                  <div key={ index } className="mb-3">
                    <p style={{ marginBottom: 5 }}>{ index + 1 }. { item.stage_name }</p>
                    {
                      item.stage_milestone && item.stage_milestone.length > 0 && (
                        <div className='project-detail__milestone-cont'>
                          {
                            item.stage_milestone.map(( milestone, milestoneIndex ) => {
                              return(
                                <div key={ milestoneIndex } className="project-detail__milestone-item">
                                  {
                                    milestoneFieldData.map(( milestoneField, milestoneFieldIndex ) => {
                                      return(
                                        <div
                                          key={ milestoneFieldIndex }
                                          style={{ ...milestoneField.containerStyle }}
                                          className='project-detail__milestone-item-content' >
                                          {
                                            milestoneIndex === 0 && (
                                              <label>{ milestoneField.label }</label>
                                            )
                                          }
                                          {
                                            milestoneField.value === 'milestone_status' &&
                                              <Tooltip placement="top" title={ milestone[ milestoneField.value ] || '-' }>
                                                <p
                                                  style={{ backgroundColor: renderBackground( milestone[ milestoneField.value ] ) }}
                                                  className='project-detail__milestone-status'>
                                                </p>
                                              </Tooltip>
                                          }
                                          {
                                              milestoneField.value.includes( 'date' ) &&
                                                <p style={{ fontWeight: 500, fontSize: 14 }}>{
                                                  milestone[ milestoneField.value ]
                                                  ? Moment( milestone[ milestoneField.value ] ).format( 'MMM YYYY' )
                                                  : '-'
                                                }</p>
                                            }
                                          {
                                            milestoneField.value === 'milestone_fund_utilization' &&
                                              <p style={{ fontWeight: 500, fontSize: 14 }}>
                                                {
                                                  _.find( disbursementList, { value: milestone[ milestoneField.value ]  } )
                                                    ? _.find( disbursementList, { value: milestone[ milestoneField.value ]  } ).label
                                                    : '-' 
                                                }
                                              </p>
                                          }
                                          {
                                            [ 'milestone_title', 'milstone_kpi', 'milstone_actual_kpi' ].includes( milestoneField.value ) &&
                                            <p style={{ fontSize: 14, textAlign: milestoneField.value === 'milestone_title' ? 'justify' : 'center' }}>{ milestone[ milestoneField.value ] || '-' }</p>
                                          }
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                )
              })
            }
          </Collapse>
        )
      }
        {
          !( stage_and_milestone && stage_and_milestone.length > 0 ) && (
            <p>{ getTranslation( language, 'No stage and milestone define.' ) }</p>
          )
        }
    </div>
  )

  const renderKPI = () => (
    <div className="project-detail__detail-cont" style={{ margin: 10 }}>
      <h2 className="content-title">
        KPI
        {
          kpi_indication_list && kpi_indication_list.length > 0 && (
            <Tooltip placement='top' title={ showKPI ? 'Click to hide' : 'Click to expand' }>
              <button 
                className='button-expand'
                onClick={ () => setShowKPI( !showKPI )}>
                <KeyboardArrowDown style={{ transform: showKPI && 'rotateZ(180deg)'}}/>
              </button>
            </Tooltip>
          )
        }
      </h2>
      {
        kpi_indication_list && kpi_indication_list.length > 0 && (
          <Collapse in={ showKPI }>
            {
              kpi_indication_list.map(( item, index ) => {
                return(
                  <div key={ index } className="project-detail__milestone-item">
                    {
                      kpiFieldData.map(( field, fieldIndex ) => {
                        return(
                          <div key={ fieldIndex } className='project-detail__milestone-item-content' >
                            {
                              index === 0 && <label>{ field.label }</label>
                            }
                            {
                              field.value === 'kpi_document_url' && 
                              <button className='btn p-0 d-flex align-items-center w-100' style={{ color: '#cb1515' }} onClick={ () => window.open( item[ field.value ], '_blank' ) }>
                                {
                                  item[ field.value ]
                                    ? (
                                      <>
                                        <span className='text-ellipsis' style={{ fontSize: 12, maxWidth: 100 }}>{ item.kpi_document_file_name }</span>
                                        <ImFilePdf style={{ color: '#cb1515', width: 12, height: 12 }}/>
                                      </>
                                    )
                                    : '-'
                                }
                              </button>
                            }
                            {
                              field.value === 'kpi_status' && 
                                <p className='badge__status' style={{ ...badgeStatus( item[ field.value ] ), width: 'fit-content', fontSize: 12 }}>{ item[ field.value ] }</p>
                            }
                            {
                              ![ 'kpi_document_url', 'kpi_status' ].includes( field.value ) &&
                                <p style={{ fontSize: 14 }}>{ item[ field.value ] || '-' }</p>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </Collapse>
        )
      }
      {
        !( kpi_indication_list && kpi_indication_list.length > 0 ) && (
          <>
            <p>{ getTranslation( language, 'No KPI define.' ) }</p>
          </>
        )
      }
    </div>
  )

  const renderAcitivityReport = () => (
    <div className="project-detail__detail-cont" style={{ margin: 10 }}>
      <h2 className="content-title">
        { getTranslation( language, 'Activity Report' ) }
        {
          activity_report_list && activity_report_list.length > 0 && (
            <Tooltip placement='top' title={ showActivityReport ? 'Click to hide' : 'Click to expand' }>
              <button 
                className='button-expand'
                onClick={ () => setShowActivityReport( !showActivityReport )}>
                <KeyboardArrowDown style={{ transform: showActivityReport && 'rotateZ(180deg)'}}/>
              </button>
            </Tooltip>
          )
        }
      </h2>
      {
        activity_report_list && activity_report_list.length > 0 && (
          <Collapse in={ showActivityReport }>
            {
              activity_report_list.map(( item, index ) => {
                return(
                  <div key={ index } className="project-detail__milestone-item">
                    {
                      activityFieldData.map(( field, fieldIndex ) => {
                        return(
                          <div key={ fieldIndex } className='project-detail__milestone-item-content' >
                            {
                              index === 0 && <label>{ field.label }</label>
                            }
                            {
                              field.value.includes( 'link' ) && (
                                <button className='btn p-0 d-flex align-items-center' style={{ color: '#00a0ff' }} onClick={ () => window.open( item[ field.value ], '_blank' ) }>
                                  {
                                    item[ field.value ]
                                    ? (
                                      <>
                                        <span className='text-ellipsis' style={{ fontSize: 12, maxWidth: 100 }}>{ item[ field.value ] }</span>
                                        <OpenInNew style={{ color: '#00a0ff', width: 12, height: 12 }}/>
                                      </>
                                    )
                                    : '-'
                                  }
                                </button>
                              )
                            }
                            {
                              field.value === 'status' && <p className='badge__status' style={{ ...badgeStatus( item[ field.value ] ), width: 'fit-content', fontSize: 12 }}>{ item[ field.value ] }</p>
                            }
                            {
                              !( field.value.includes( 'link' ) || field.value === 'status' ) && <p style={{ fontSize: 14 }}>{ item[ field.value ] || '-' }</p>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </Collapse>
        )
      }
      {
        !( activity_report_list && activity_report_list.length > 0 ) && <p>{ getTranslation( language, 'No Activity Report.' ) }</p>
      }
    </div>
  )

  const renderDisbursement = () => (
    <div className="project-detail__detail-cont" style={{ margin: 10 }}>
      <h2 className="content-title">
        { getTranslation( language, 'Disbursement' ) }
        {
          disbursement_progress_list && disbursement_progress_list.length > 0 && (
            <Tooltip placement='top' title={ showDisbursement ? 'Click to hide' : 'Click to expand' }>
              <button 
                className='button-expand'
                style={{ transform: showDisbursement && 'rotateZ(180deg)'}}
                onClick={ () => setShowDisbursement( !showDisbursement )}>
                <KeyboardArrowDown />
              </button>
            </Tooltip>
          )
        }
      </h2>
      {
        disbursement_progress_list && disbursement_progress_list.length > 0 && (
          <Collapse in={ showDisbursement }>
            {
              disbursement_progress_list.map(( item, index ) => {
                return(
                  <div key={ index } className="project-detail__milestone-item">
                    {
                      disbursementFieldData.map(( field, fieldIndex ) => {
                        return(
                          <div key={ fieldIndex } className='project-detail__milestone-item-content' >
                            {
                              index === 0 && <label>{ field.label }</label>
                            }
                            {
                              field.value !== 'disbursement_file_url'
                                ? <p style={{ fontSize: 14 }}>{ item[ field.value ] || '-' }</p>
                                : (
                                  <button className='btn p-0 d-flex align-items-center w-100' style={{ color: '#cb1515' }} onClick={ () => window.open( item[ field.value ], '_blank' ) }>
                                      {
                                        item[ field.value ]
                                          ? (
                                            <>
                                              <span className='text-ellipsis' style={{ fontSize: 12, maxWidth: 100 }}>{ item.disbursement_file_name }</span>
                                              <ImFilePdf style={{ color: '#cb1515', width: 12, height: 12 }}/>
                                            </>
                                          )
                                          : '-'
                                      }
                                    </button>
                                )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </Collapse>
        )
      }
      {
        !( disbursement_progress_list && disbursement_progress_list.length > 0 ) && (
          <p>{ getTranslation( language, 'No disbursement define.' ) }</p>
        )
      }
    </div>
  )

  const renderUtilization = () => (
    <div className="project-detail__detail-cont" style={{ margin: 10 }}>
      <h2 className="content-title">
        { getTranslation( language, 'Utilization Progress' ) }
        {
          utilization_progress_list && utilization_progress_list.length > 0 && (
            <Tooltip placement='top' title={ showUtilization ? 'Click to hide' : 'Click to expand' }>
              <button 
                className='button-expand'
                style={{ transform: showUtilization && 'rotateZ(180deg)'}}
                onClick={ () => setShowUtilization( !showUtilization )}>
                <KeyboardArrowDown />
              </button>
            </Tooltip>
          )
        }
      </h2>
      {
        utilization_progress_list && utilization_progress_list.length > 0 && (
          <Collapse in={ showUtilization }>
            {
              utilization_progress_list.map(( item, index ) => {
                return(
                  <div key={ index } className="project-detail__milestone-item">
                    {
                      utilizationFieldData.map(( field, fieldIndex ) => {
                        return(
                          <div key={ fieldIndex } className='project-detail__milestone-item-content' >
                            {
                              index === 0 && <label>{ field.label }</label>
                            }
                            {
                              field.value !== 'utilization_file_url'
                                ? <p style={{ fontSize: 14 }}>{ item[ field.value ] || '-' }</p>
                                : (
                                  <button className='btn p-0 d-flex align-items-center w-100' style={{ color: '#cb1515' }} onClick={ () => window.open( item[ field.value ], '_blank' ) }>
                                    {
                                      item[ field.value ]
                                        ? (
                                          <>
                                            <span className='text-ellipsis' style={{ fontSize: 12, maxWidth: 100 }}>{ item.utilization_file_name }</span>
                                            <ImFilePdf style={{ color: '#cb1515', width: 12, height: 12 }}/>
                                          </>
                                        )
                                        : '-'
                                    }
                                  </button>
                                )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </Collapse>
        )
      }
      {
        !( utilization_progress_list && utilization_progress_list.length > 0 ) && <p>{ getTranslation( language, 'No utilization define.' ) }</p>
      }
    </div>
  )

  const renderProjectDetail = (key) => {
    const value = projectDetail[key]
    if (typeof value === 'string') {
      if (key === 'start_year') {
        return Moment(value).format('MMM YYYY')
      } else if (key === 'total_grant') {
        return 'RM ' + thousandSeperatorFormatter(value)
      } else {
        return value
      }
    } else if (typeof value === 'object') {
      return value.map((item) => item).join(', ')
    }
  }

  return(
    <>
      <div className="project-detail__detail-cont-bg row" style={{ margin: 10 }}>
        <h2 className="content-title">{ getTranslation( language, 'Project Details' ) }</h2>
        {
          projectField.map(( item, index ) => {
            return(
              <div key={ index } className={ item.value === 'project_description' ? 'col-lg-6 col-md-8' : 'col-lg-3 col-md-4' }>
                <label>{ item.label }</label>
                <p style={item.value === 'project_description' ? {textAlign: 'justify'} : {}}>{ renderProjectDetail(item.value) }</p>
              </div>
            )
          })
        }
      </div>
      { renderStageMilestone() }
      { renderKPI() }
      { renderAcitivityReport() }
      { renderDisbursement() }
      { renderUtilization() }
    </>
  )
}

export default Overview;
import React, { useEffect } from 'react'
import { EditOutlined, DeleteForeverRounded, PictureAsPdfRounded } from '@mui/icons-material'

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import DeleteConfirmation from 'components/Card/Confirmation'

import EditDisbursement from './disbursement'
import CreateDisbursement from './disbursement'

import { getTranslation } from 'utils/renderLanguage'
import { thousandSeperatorFormatter } from 'utils/thousandSeparator'
import FinancialHOC from './actions'

const disbursementColumn = ( language ) => [
  { header: getTranslation( language, 'Disbursement' ), accessor: 'disbursement_title', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Funding' ), accessor: 'funding', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Amount' ), containerStyle: { width: '50%' },
    renderColumn: rowData => thousandSeperatorFormatter( rowData.amount )
  },
  { header: getTranslation( language, 'Date of Disbursement' ), accessor: 'disbursement_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Project Completion(%)' ), accessor: 'project_completion', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Support Document' ), containerStyle: { width: '50%' },
    renderColumn: rowData => (
      <button className='btn' onClick={ () => window.open( rowData.disbursement_file_url, '_blank' ) }>
        {
          rowData.disbursement_file_url ? <PictureAsPdfRounded style={{ color: '#cb1515' }}/> : '-'
        }
      </button>
    )
  },
]

const Financial = props => {

  const { language }= props

  useEffect( () => {
    props.getDisbursements( props.projectDetail.disbursement_progress )
  }, [ props.projectDetail ])

  return(
    <>
      <div className='mx-4'>
        <div className={ 'd-flex align-items-end mb-2' }>
          <h2 style={{ fontSize: 20, fontWeight: 500 }}>{ getTranslation( language, 'Disbursement Progress' ) }</h2>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 'auto' }}
            onClick={ () => props.onChangeFinancialHOC( true, 'showCreateDisbursementModal' ) }>
            { getTranslation( language, 'Create New Disbursement' ) }
          </button>
        </div>
        <PaginatedTable
          rowData={ props.disbursement }
          columnData={ disbursementColumn( language ) }
          actionsColumn={[{
            name: 'Edit',
            renderActionIcon: <EditOutlined />,
            onClick: rowData => Promise.all([
              props.onChangeFinancialHOC( rowData, 'selectedDisbursement' )
            ]).then( () => {
              props.onChangeFinancialHOC( true, 'showEditDisbursementModal' )
            })
          }, {
            name: 'Delete',
            style: { background: '#cb1515' },
            renderActionIcon: <DeleteForeverRounded />,
            onClick: rowData => Promise.all([
              props.onChangeFinancialHOC( rowData, 'selectedDisbursement' )
            ]).then( () => {
              props.onChangeFinancialHOC( true, 'showDeleteDisbursementModal' )
            })
          }]}
        />
      </div>
      {
        props.showCreateDisbursementModal && <CreateDisbursement
          mode={ 'create' }
          language={ language }
          title={ getTranslation( language, 'Create New Disbursement' ) }
          projectDetail={ props.projectDetail }
          createDisbursement={ props.createDisbursement }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showCreateDisbursementModal' ) }  
        />
      }
      {
        props.showEditDisbursementModal && <EditDisbursement
          mode={ 'update' }
          language={ language }
          title={ getTranslation( language, 'Edit Disbursement' ) }
          projectDetail={ props.projectDetail }
          updateDisbursement={ props.updateDisbursement }
          selectedDisbursement={ props.selectedDisbursement }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showEditDisbursementModal' ) }  
        />
      }
      {
        props.showDeleteDisbursementModal && <DeleteConfirmation
          isOpen={ true }
          onClick={ () => props.deleteDisbursement( props.selectedDisbursement.disbursement_id ) }
          confirmationText={
            <>
              { getTranslation( language, 'You are going to remove the selected disbursement record from the project?' ) }
              <br/><br/>
              <b className='text-danger'>{ getTranslation( language, 'This action is non-reversible.' ) }</b>
            </>
          }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showDeleteDisbursementModal' ) }
        />
      }
      { props.onLoadFinancial && <LoadingModal /> }
    </>
  )
}

export default FinancialHOC( Financial )
import EmptyState from 'components/EmptyState';
import React from 'react'

import './index.scss'

const CardList = props => {

  return (
    <>
      {
        ( props.data && props.data.length > 0 ) && (
          <div className='row w-100'>
            {
              props.data.map(( item, index ) => (
                <div key={ index } className='col-xl-4 col-lg-6 mb-2'>
                  <div className='at-card-list__card-cont'>
                    {
                      props.columns && props.columns.map(( column, columnIndex ) => (
                        <div key={ columnIndex } className={ column.className }>
                          {
                            column.header && <label>{ column.header }</label>
                          }
                          {
                            column.renderColumn && column.renderColumn( item, index )
                          }
                          {
                            !column.renderColumn && (
                              <p>{ item[ column.accessor ] }</p>
                            )
                          }
                        </div>
                      ))
                    }
                    {
                      props.renderStatus ? props.renderStatus( item.status ) : (
                        <div className='at-card-list__status' style={{ background: item.status === 'Active' ? 'green' : '#c32222' }}>
                          { item.status }
                        </div>
                      )
                    }
                    {
                      ( props.actions && props.actions.length > 0 ) && (
                        <div className='at-card-list__action-cont'>
                          {
                            props.actions.map(( action, actionIndex ) => (
                              <button 
                                key={ actionIndex }
                                className='btn btn-primary w-100 mx-2'
                                onClick={ () => action.onClick( item ) } >
                                { action.label }
                              </button>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
      {
        !( props.data && props.data.length > 0 ) && (
          <div className='at-card-list__cont'>
            <EmptyState title={ 'No Result Found' } />
          </div>
        )
      }
    </>
  )
}

export default CardList;
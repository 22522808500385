import React, { useEffect } from 'react'
import localStorage from 'localStorage'
import { useOutletContext } from 'react-router-dom';
import { Info } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'

import { getTranslation } from 'utils/renderLanguage'
import { assessmentStatus } from 'utils/stageStatus'
import ProjectAssessmentHOC from './actions'

const language = localStorage.getItem( 'english' )

const columnData = [
  { header: getTranslation( language, 'Assessor' ), accessor: 'assessor_name' },
  { header: getTranslation( language, 'Date' ), accessor: 'date' },
  { header: getTranslation( language, 'Total Score' ), accessor: 'jumlah_markah' },
  { header: getTranslation( language, 'Rated at' ), accessor: 'updated_at' },
  { header: getTranslation( language, 'Rating' ), renderColumn: rowData => (
    <p className='badge__status' style={{ width: 'fit-content', ...assessmentStatus( rowData.status ) }}>
      { getTranslation( language, rowData.status ) }
    </p>
  )}
]

const ProjectAssessment = props => {

  const outletContext = useOutletContext()
  const { uid, role } = outletContext.user

  useEffect( () => {
    if( role === 'Assessor' ){
      props.getProjectAssessment( props.projectDetail.project_id, uid )
    } else {
      props.getProjectAssessment( props.projectDetail.project_id )
    }
  }, [ props.projectDetail ])

  return(
    <>
      <div className='d-flex justify-content-between mb-2'>
        <label className='at-project-mngmt__form-label'>{ getTranslation( language, 'Project Assessment' ) }</label>
      </div>
      <PaginatedTable
        rowData={ props.assessmentList || [] }
        columnData={ columnData }
        actionsColumn={[{
          name: 'View',
          onShow: rowData => rowData.updated_at || outletContext.user.role === 'Assessor' ,
          renderActionIcon: <Info />,
          onClick: rowData => outletContext.onNavigate( `/dashboard/project-assessment/${ props.projectDetail.project_id }/${ rowData.assessment_id }` )
        }]}
      />
      { props.onLoadProjectAssessment && <LoadingModal /> }
    </>
  )
}

export default ProjectAssessmentHOC( ProjectAssessment );
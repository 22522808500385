import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import localStorage from 'localStorage'
import { ChevronLeft, ChevronRight, ExpandMore, ExpandLess, UnfoldMore } from '@mui/icons-material'
import { getTranslation } from 'utils/renderLanguage'

import './index.scss'

const PaginatedTable = ({
  className,
  style,
  hidePagination,
  
  rowData,
  columnData,
  actionsColumn,
}) => {

  const language = localStorage.getItem( 'english' )
  const [ viewPage, setViewPage ] = useState(1)
  const [ pageNumber, setPageNumber ] = useState([ 1 ])
  const [ displayData, setDisplayData ] = useState([])
  const [ sortedColumn, setSortedColumn ] = useState({})

  useEffect( () => {
    let temp = _.cloneDeep( rowData )
    let page_number = 1
    if( rowData.length > 10 && !hidePagination ){
      page_number = Math.floor( rowData.length / 10 )
      if( rowData.length % 10 > 0 ){
        page_number++
      }
      temp.splice( 10, rowData.length )
    }
    setPageNumber( Array.from({ length: page_number }, ( x, i ) => i + 1 ) )
    setDisplayData( temp )
  }, [ rowData ] )

  useEffect( () => {
    let temp = _.cloneDeep( rowData )
    if( !hidePagination ){
      let startIndex = 10 * ( viewPage - 1 )
      temp.splice( 0, startIndex )
      temp.splice( startIndex + 10, rowData.length )
    }
    setDisplayData( temp )
  }, [ viewPage ])

  const onClickSort = ( column ) => {
    let temp = _.cloneDeep( displayData )
    if( sortedColumn.column === column.accessor ){
      temp = temp.reverse()
      setSortedColumn({ column: column.accessor, order: sortedColumn.order === 'asc' ? 'desc' : 'asc' })
    } else {
      temp = temp.sort(( a, b ) => {
        if( a[ column.accessor ] < b[ column.accessor ] ){
          return sortedColumn.order === 'asc' ? -1 : 1
        }
        if( a[ column.accessor ] > b[ column.accessor ] ){
          return sortedColumn.order === 'asc' ? 1 : -1
        }
        return 0
      })
      setSortedColumn({ column: column.accessor, order: 'asc' })
    }
    setDisplayData( temp )
  }

  return(
    <div className={ `overflow-auto ${ className || '' }` }  style={{ ...style }}>
      <div className='paginated-header'>
        {
          columnData.map(( item, index ) => {
            return(
              <div 
                key={ index }
                style={{ ...item.containerStyle }}
                className={ `paginated-table__header-cont` }
              >
                <p className='w-100'>{ item.header }</p>
                { item.accessor && (
                  <button
                    className='paginated-table__header-sort-btn'
                    onClick={ () => onClickSort( item ) }>
                    {sortedColumn.column === item.accessor && sortedColumn.order === 'asc' && <ExpandMore />}
                    {sortedColumn.column === item.accessor && sortedColumn.order === 'desc' && <ExpandLess />}
                    {sortedColumn.column !== item.accessor && <UnfoldMore />}
                  </button>
                ) }
              </div>
            )
          })
        }
        {
          ( actionsColumn && actionsColumn.length > 0 ) && (
            <div
              style={{ width: `${ actionsColumn.length * 25 }%`, minWidth: 70 }} 
              className={ `paginated-table__header-cont` }>
              <p>{ getTranslation( language, 'Actions' ) }</p>
            </div>
          )
        }
      </div>
      <div className='paginated-content'>
        {
          ( displayData && displayData.length > 0 ) && displayData.map(( data, dataIndex ) => {
            return(
              <div key={ dataIndex } className='paginated-table__content-cont' >
                {
                  columnData.map(( column, columnIndex ) => {
                    return(
                      <div
                        key={ columnIndex }
                        style={{ ...column.containerStyle }}
                        className='paginated-table__content-column'
                      >
                        { (column.accessor && !column.renderColumn) && <p>{ data[ column.accessor ] || '-' }</p> }
                        { column.renderColumn && column.renderColumn( data, dataIndex ) }
                      </div>
                    )
                  })
                }
                {
                  ( actionsColumn && actionsColumn.length > 0 ) && (
                    <div 
                      style={{ width: `${ actionsColumn.length * 25 }%`, minWidth: 70 }}
                      className={ `paginated-table__content-column flex-wrap` }>
                      {
                        actionsColumn.map(( item, index ) => {
                          if( !item.onShow || ( item.onShow && item.onShow( data )) ){
                            return(
                              <button
                                key={ index }
                                disabled={ item.disabled }
                                style={{ ...item.style }}
                                className={ item.renderActionIcon
                                  ? 'paginated-table__content-action-btn'
                                  : `btn ${ item.className }` 
                                }
                                onClick={ () => item.onClick( data, dataIndex ) }
                              >
                                { item.renderActionIcon || getTranslation( language, item.name ) }
                              </button>
                            )
                          }
                        })
                      }
                    </div>
                  )
                }
                {
                  !( actionsColumn && actionsColumn.length > 0 ) && (
                    <p>{ getTranslation( language, 'No action required' ) }</p>
                  )
                }
              </div>
            )
          })
        }
        {
          ( displayData && displayData.length === 0 ) && (
            <div className='paginated-table__content-column'>
              <h4 className='at-card-list__no-result w-100'>{ getTranslation( language, 'No Result Found' ) }</h4>
            </div>
          )
        }
      </div>
      {
        ( rowData.length > 10 && !hidePagination) && (
          <>
            <div className='paginated-pagination_data-count'>
              { getTranslation( language, 'Showing from ' ) }<span>{ 10 * ( viewPage - 1 ) + 1 }</span> - <span>{ ( 10 * viewPage ) > rowData.length ? rowData.length : ( 10 * viewPage ) }</span> of <span>{ rowData.length }</span> results
            </div>
            <div className='paginated-pagination'>
              <button
                className='paginated-pagination__button'
                disabled={ viewPage === 1 }
                onClick={ () => setViewPage( viewPage - 1 ) }
              >
                <ChevronLeft style={{ width: 14, height: 14 }}/>
              </button>
              {
                pageNumber.map(( value, index ) => {
                  return(
                    <button 
                      key={ index }
                      onClick={ () => setViewPage( Number( value ) ) }
                      className={ `paginated-pagination__button ${ viewPage === value && 'paginated-pagination__button-selected' }` }>
                      { value }
                    </button>
                  )
                })
              }
              <button
                className='paginated-pagination__button'
                disabled={ viewPage === pageNumber.length }
                onClick={ () => setViewPage( viewPage + 1 ) }
              >
                <ChevronRight style={{ width: 14, height: 14 }}/>
              </button>
            </div>
          </>
        )
      }
    </div>
  )
}

export default PaginatedTable;
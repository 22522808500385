import React, { Component } from 'react'
import _ from 'lodash'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      showEditDisbursementModal: false,

      showEditUtilizationModal: false,
      showCreateUtilizationModal: false,
      showDeleteUtilizationModal: false,

      disbursement: [],
      selectedDisbursement: {},
      utilization: [],
      selectedUtilization: {},
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeFinancialHOC = ( val, context ) => this.setState({ [ context ]: val })

    getDisbursements = disbursements => Get(
      `/financial-getDisbursements?disbursements=${ JSON.stringify( disbursements ) }`,
      this.getDisbursementsSuccess,
      this.getDisbursementsError,
      this.load
    )
    getDisbursementsSuccess = payload => this.setState({ disbursement: _.orderBy(payload.data.disbursement, ['created_at', 'disbursement_title'], ['asc', 'asc']) })
    getDisbursementsError = error => this.requestError( error )
    
    getUtilizations = utilizations => Get(
      `/financial-getUtilizations?utilizations=${ JSON.stringify( utilizations ) }`,
      this.getUtilizationsSuccess,
      this.getUtilizationsError,
      this.load
    )
    getUtilizationsSuccess = payload => this.setState({ utilization: _.orderBy(payload.data.utilizations, ['created_at', 'disbursement_title'], ['asc', 'asc']) })
    getUtilizationsError = error => this.requestError( error )

    createUtilization = dataToSubmit => Post(
      `/financial-newUtilization`,
      {...dataToSubmit, created_at: new Date()},
      this.createUtilizationSuccess,
      this.createUtilizationError,
      this.load
    )
    createUtilizationSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateUtilizationModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    createUtilizationError = error => this.requestError( error )

    updateDisbursement = dataToSubmit => Post(
      `/financial-updateDisbursement`,
      {...dataToSubmit, created_at: new Date()},
      this.updateDisbursementSuccess,
      this.updateDisbursementError,
      this.load
    )
    updateDisbursementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showEditDisbursementModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    updateDisbursementError = error => this.requestError( error )

    updateUtilization = dataToSubmit => Post(
      `/financial-updateUtilization`,
      {...dataToSubmit, created_at: new Date()},
      this.updateUtilizationSuccess,
      this.updateUtilizationError,
      this.load
    )
    updateUtilizationSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showEditUtilizationModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    updateUtilizationError = error => this.requestError( error )

    deleteUtilization = id => Delete (
      `/financial-deleteUtilization?id=${ id }`,
      this.deleteUtilizationSuccess,
      this.deleteUtilizationError,
      this.load
    )
    deleteUtilizationSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showDeleteUtilizationModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    deleteUtilizationError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadFinancial={ this.state.loading }

            getUtilizations={ this.getUtilizations }
            createUtilization={ this.createUtilization }
            updateUtilization={ this.updateUtilization }
            deleteUtilization={ this.deleteUtilization }
            getDisbursements={ this.getDisbursements }
            updateDisbursement={ this.updateDisbursement }
            onChangeFinancialHOC={ this.onChangeFinancialHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC
import React, { Component } from 'react'
import Moment from 'moment';

import CustomSnackbar from 'components/Snackbar';

import { Get } from 'utils/axios'

const yearsBack = (back) => {
  const year = new Date().getFullYear();
  return Array.from({ length: back }, (v, i) => year - back + i + 1);
}

const yearsFront = (front) => {
  const year = new Date().getFullYear();
  return Array.from({ length: front }, (v, i) => year + i + 1);
}

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},
      searchParams: [
        { label: 'Funder', value: 'funder', param: '' },
        { label: 'Program', value: 'project_name', param: '' },
        { label: 'Implementing Agency', value: 'implementing_agency', param: '' },
        { label: 'Year', value: 'selected_year', param: '' },
      ],

      loading: false,
      loadingCount: 0,
      dataValue: {
        total_project: 0,
        active_project: 0,
        pending_project: 0,
        rejected_project: 0,
      },
      projectList: [],
      searchProjectList: [],
      selectedProject: {},
      yearSelection: [
        ...yearsBack( 5 ), 
        ...yearsFront( 5 )
      ],
      selected_year: Moment().year().toString(),
      
      showDetailForm: false,
      showDeleteModal: false,
      showCreateModal: false,
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjects = id => Get(
      `/project-getMultipleProject?id=${ id }`,
      this.getProjectsSuccess,
      this.getProjectsError,
      this.load
    )
    getProjectsSuccess = payload => this.setState({ 
      projectList: payload.data.projects,
      searchProjectList: payload.data.projects,
      dataValue: payload.data.summary
    })
    getProjectsError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectManagement={ this.state.loading }

            getProjects={ this.getProjects }
            onChangeProjectManagementHOC={ this.onChangeProjectManagementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC
import localStorage from 'localStorage'

import { getTranslation } from 'utils/renderLanguage'
import { thousandSeperatorFormatter } from 'utils/thousandSeparator'

const language = localStorage.getItem( 'english' )

export const milestonStatus = [
  { label: getTranslation( language, 'Not Started' ), color: '#adb5bd', description: "Pelaksanaan belum bermula" },
  { label: getTranslation( language, 'On Track' ), color: '#2dd16b', description: "Masih dalam proses pelaksanaan" },
  { label: getTranslation( language, 'Over Achievement' ), color: '#2dc5d1', description: "Pelaksanaan selesai sebelum Tarikh tamat KPI (warna ini akan kekal, tidak akan bertukar purple)" },
  { label: getTranslation( language, 'Delayed < 2 weeks' ), color: '#f9c828', description: "Pelaksanaan lewat bawah 2 minggu" },
  { label: getTranslation( language, 'Delayed > 2 weeks' ), color: '#f93728', description: "Pelaksanaan lewat atas 2 minggu" },
  { label: getTranslation( language, 'Completed' ), color: '#6b28f9', description: "Pelaksanaan selesai walaupun lewat" }
]

export const cardList = [
  { label: getTranslation( language, 'Project Started' ), value: 'start_year' },
  { label: getTranslation( language, 'Next Submission Date' ), value: 'submission_date' },
  { label: getTranslation( language, 'Current Stage' ), value: 'current_stage' },
  { label: getTranslation( language, 'Current Milestone' ), value: 'current_milestone' },
]

export const projectField = [
  { label: getTranslation( language, 'Project Name' ), value: 'project_name' },
  { label: getTranslation( language, 'Start Year' ), value: 'start_year' },
  { label: getTranslation( language, 'Description' ), value: 'project_description' },
  { label: getTranslation( language, 'Implementing Agency' ), value: 'implementing_agency' },
  { label: getTranslation( language, 'Assessor' ), value: 'assessor' },
  { label: getTranslation( language, 'Project Leader' ), value: 'project_leader' },
  { label: getTranslation( language, 'Funder' ), value: 'funder' },
  { label: getTranslation( language, 'Total Grant' ), value: 'total_grant' },
  { label: getTranslation( language, 'Current Stage' ), value: 'current_stage' },
  { label: getTranslation( language, 'State' ), value: 'state' },
  { label: getTranslation( language, 'Status' ), value: 'status' }
]

export const milestoneFieldData = [
  { label: getTranslation( language, 'Milestone Title' ), value: 'milestone_title', containerStyle: { width: '25%', minWidth: 200 } },
  { label: getTranslation( language, 'KPI(%)' ), value: 'milstone_kpi', containerStyle: { width: '4%', minWidth: 50 } },
  { label: getTranslation( language, 'Actual KPI(%)' ), value: 'milstone_actual_kpi', containerStyle: { width: '4%', minWidth: 80 } },
  { label: getTranslation( language, 'Acc. KPI(%)' ), value: 'accumulated_kpi', containerStyle: { width: '4%', minWidth: 80 } },
  { label: getTranslation( language, 'Start Date' ), value: 'milestone_start_date', containerStyle: { width: '12%', minWidth: 80 } },
  { label: getTranslation( language, 'End Date' ), value: 'milestone_end_date', containerStyle: { width: '12%', minWidth: 80 } },
  { label: getTranslation( language, 'Actual Start Date' ), value: 'milestone_actual_start_date', containerStyle: { width: '12%', minWidth: 80 } },
  { label: getTranslation( language, 'Actual End Date' ), value: 'milestone_actual_end_date', containerStyle: { width: '12%', minWidth: 80 } },
  { label: getTranslation( language, 'Fund Utilization' ), value: 'milestone_fund_utilization', containerStyle: { width: '15%', minWidth: 80 } },
  { label: getTranslation( language, 'Status' ), value: 'milestone_status', containerStyle: { width: '5%', minWidth: 40 } },
]

export const activityFieldData = [
  { label: getTranslation( language, 'Submitted Date' ), value: 'submited_date', containerStyle: { width: '15%', minWidth: 150 } },
  { label: getTranslation( language, 'Estimate Completion(%)' ), value: 'completion_percentage', containerStyle: { width: '15%', minWidth: 250 } },
  { label: getTranslation( language, 'Video Folder Link' ), value: 'video_folder_link', containerStyle: { width: '15%', minWidth: 150 } },
  { label: getTranslation( language, 'Publication Folder Link' ), value: 'publication_folder_link', containerStyle: { width: '15%', minWidth: 150 } },
  { label: getTranslation( language, 'Image Folder Link' ), value: 'image_folder_link', containerStyle: { width: '15%', minWidth: 150 } },
  { label: getTranslation( language, 'Other Folder Link' ), value: 'others_folder_link', containerStyle: { width: '15%', minWidth: 150 } },
  { label: getTranslation( language, 'Status' ), value: 'status', containerStyle: { width: '10%', minWidth: 100 } },
]

export const disbursementFieldData = [
  { label: getTranslation( language, 'Date' ), value: 'disbursement_date', containerStyle: { width: '15%', minWidth: 150 } },
  { label: getTranslation( language, 'Disbursement' ), value: 'disbursement_title', containerStyle: { width: '25%', minWidth: 250 } },
  { label: getTranslation( language, 'Funding' ), value: 'funding', containerStyle: { width: '15%', minWidth: 150 } },
  { header: getTranslation( language, 'Amount' ), containerStyle: { width: '15%', minWidth: 150 },
    renderColumn: rowData => thousandSeperatorFormatter( rowData.amount )
  },
  { label: getTranslation( language, 'Completion(%)' ), value: 'project_completion', containerStyle: { width: '15%', minWidth: 100 } },
  { label: getTranslation( language, 'Document' ), value: 'disbursement_file_url', containerStyle: { width: '5%', minWidth: 50 } },
]

export const utilizationFieldData = [
  { label: getTranslation( language, 'Date' ), value: 'utilication_date', containerStyle: { width: '25%', minWidth: 150 } },
  { label: getTranslation( language, 'Utilization(RM)' ), value: 'utilization_amount', containerStyle: { width: '25%', minWidth: 250 } },
  { label: getTranslation( language, 'Utilization(%)' ), value: 'utilization_percentage', containerStyle: { width: '25%', minWidth: 150 } },
  { label: getTranslation( language, 'Document' ), value: 'utilization_file_url', containerStyle: { width: '25%', minWidth: 50 } },
]

export const kpiFieldData = [
  { label: getTranslation( language, 'Key Performance Indicator(KPI)' ), value: 'kpi_title' },
  { label: getTranslation( language, 'Description' ), value: 'kpi_description' },
  { label: getTranslation( language, 'Submission Date' ), value: 'kpi_submission_date' },
  { label: getTranslation( language, 'Notes' ), value: 'kpi_submission_note' },
  { label: getTranslation( language, 'Document' ), value: 'kpi_document_url' },
  { label: getTranslation( language, 'Status' ), value: 'kpi_status' },
]

export const achievementFieldData = [
  { label: getTranslation( language, 'Achievement' ), value: 'achievement_title' },
  { label: getTranslation( language, 'Description' ), value: 'achievement_description' },
  { label: getTranslation( language, 'Submission Date' ), value: 'achievement_submission_date' },
  { label: getTranslation( language, 'Notes' ), value: 'achievement_submission_note' },
  { label: getTranslation( language, 'Document' ), value: 'achievement_document_url' },
  { label: getTranslation( language, 'Status' ), value: 'achievement_status' },
]
import React, { useState } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import { Form, Modal, Button } from "react-bootstrap"

import { updateMilestoneField } from '../../assets'

const ProjectPerformanceForm = props => {

  const {
    project_id,
    stage_and_milestone
  } = props.projectObj
  const selectedMileStone = stage_and_milestone[ props.selectedStageIndex ].stage_milestone[ props.selectedMilestoneIndex ]

  const [ projectUpdate, setProjectUpdate ] = useState({
    project_id: project_id,
    next_progress: {},
    current_stage: stage_and_milestone[ props.selectedStageIndex ].stage_name,
    current_progress: {
      stage: props.selectedStageIndex,
      milestone: props.selectedMilestoneIndex
    },
    milestone_update: stage_and_milestone[ props.selectedStageIndex ].stage_milestone[ props.selectedMilestoneIndex ],
  })

  const onChangeMilestone = ( val, context ) => {
    let temp = _.cloneDeep( projectUpdate )
    temp.milestone_update[ context ] = val
    setProjectUpdate( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={ 'row project-performance__stage-cont' } style={{ borderWidth: 1, margin: 5 }}>
          <div className='col-md-6'>
            <label>Current Progress Timeline</label>
            <p>{ props.projectObj.current_stage }</p>
          </div>
          <div className='col-md-6'>
            <label>Estimate KPI(%)</label>
            <p>{ selectedMileStone.milstone_kpi }</p>
          </div>
          <div className='col-md-6'>
            <label>Estimate Start Date</label>
            <p>{ selectedMileStone.milestone_start_date
              ? Moment( selectedMileStone.milestone_start_date ).format( 'MMM YYYY' )
              : '-'
            }</p>
          </div>
          <div className='col-md-6'>
            <label>Estimate End Date</label>
            <p>{ selectedMileStone.milestone_end_date
              ? Moment( selectedMileStone.milestone_end_date ).format( 'MMM YYYY' )
              : '-'
            }</p>
          </div>
          <div className='col-md-6'>
            <label>Utilization Fund</label>
            <p>{ _.find( props.disbursementList, { value: selectedMileStone.milestone_fund_utilization } )
              ? _.find( props.disbursementList, { value: selectedMileStone.milestone_fund_utilization } ).label
              : '-' 
            }</p>
          </div>
        </div>
        <div className='row'>
          {
            updateMilestoneField.map(( item, index ) => {
              if( item.type === 'select' ){
                return(
                  <Form.Group key={ index } className="mt-3 col-lg-6">
                    <Form.Label>{ item.label }</Form.Label>
                    <Form.Select 
                      aria-label={ item.label } 
                      value={ projectUpdate.milestone_update[ item.value ] }
                      onChange={ e => onChangeMilestone( e.target.value, item.value )}>
                    <option hidden value={ '' }>Select</option>
                    {
                      item.options.map(( option, index ) => (
                        <option key={ index } value={ option.value }>{ option.label }</option>
                      ))
                    }
                    </Form.Select>
                  </Form.Group>
                )
              } else {
                return(
                  <Form.Group key={ index } className='mt-3 col-lg-6'>
                    <Form.Label>{ item.label }</Form.Label>
                    <Form.Control
                      type={ item.type }
                      placeholder={ item.label }
                      value={ projectUpdate.milestone_update[ item.value ] }
                      onChange={ e => onChangeMilestone( e.target.value, item.value ) }
                    />
                  </Form.Group>
                )
              }
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={ false } 
          onClick={ () => props.updateProjectPerformance( projectUpdate ) } >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProjectPerformanceForm;
import React from 'react'
import _ from 'lodash'
import localStorage from 'localStorage'
import { Tooltip } from '@mui/material'
import { Doughnut } from 'react-chartjs-2';

import { getTranslation } from 'utils/renderLanguage'
import { assessmentStatus } from 'utils/stageStatus'
import { monthList, quaterlyList } from 'utils/dictionary';

const language = localStorage.getItem( 'english' )

export const columns = props => [
  { header: getTranslation( language, 'Project' ), accessor: 'project_name' },
  { header: getTranslation( language, 'Implementing Agency' ), accessor: 'implementing_agency' },
  { header: getTranslation( language, 'State' ), accessor: 'state' },
  { header: getTranslation( language, 'Project Performance & Activity Report' ), 
    renderColumn: rowData => (
      <div className='d-flex align-items-center flex-wrap'>
        {
          monthList.map( month => {
            let temp = rowData.activity_report_summary[ props.selected_year ]
              ? rowData.activity_report_summary[ props.selected_year ][ month ]
              : false

            return (
              <Tooltip placement="top" title={ temp ? 'Submitted' : 'Not Submitted' }>
                <p
                  style={{ 
                    color: 'white',
                    backgroundColor: temp ? 'green' : '#c32222' 
                  }}
                  className='at-project-mngmt__assessment-status'>
                  { month }
                </p>
              </Tooltip>
            )
          })
        }
      </div>
    )
  },
  { header: getTranslation( language, 'Project Rating' ), 
    renderColumn: rowData => (
      <div className='d-flex align-items-center'>
        {
          quaterlyList.map( quarter => {
            let temp = rowData.assessment_summary[ props.selected_year ]
              ? rowData.assessment_summary[ props.selected_year ][ quarter ]
              : false

            let background = temp 
              ? ( temp === 'On Target' 
                ? '#ff8d00'
                : assessmentStatus( temp ).color
              )
              : 'white'

            return(
              <Tooltip placement="top" title={ temp || 'Assessment Is Not Made' }>
                <p
                  style={{ 
                    backgroundColor: background,
                    color: background === 'white' ? 'black' : 'white',
                    border: !temp ? '1px solid grey' : ''
                  }}
                  className='at-project-mngmt__assessment-status'>
                    { quarter }
                </p>
              </Tooltip>
            )
          })
        }
      </div>
    )
  },
  { header: getTranslation( language, 'Site Audit Report' ), 
    renderColumn: rowData => (
      <div className='d-flex align-items-center'>
        {
          _.map( [ 0, 1 ], value => {
            let temp = rowData.site_audit_summary[ props.selected_year ]
              ? rowData.site_audit_summary[ props.selected_year ][ value ]
              : ''
            let background = temp ? 'green' : 'white' 

            return(
              <Tooltip placement="top" title={ temp || 'Assessment Is Not Made' }>
                <p
                  style={{ 
                    backgroundColor: background,
                    color: background === 'white' ? 'black' : 'white',
                    border: !temp ? '1px solid grey' : ''
                  }}
                  className='at-project-mngmt__assessment-status'>
                    Phase { value + 1 }
                </p>
              </Tooltip>
            )
          })
        }
      </div>
    )
  },
  { header: getTranslation( language, 'Overall Monitoring Analysis' ), 
    renderColumn: rowData => {
      let temp = rowData.overall_assessment && rowData.overall_assessment.length > 0
      let background = temp ? 'green' : 'white' 
      return (
        <div className='d-flex align-items-center'>
          <Tooltip placement="top" title={ temp ? 'Submitted' : 'Not Submitted' }>
            <p
              style={{ 
                backgroundColor: background,
                color: background === 'white' ? 'black' : 'white',
                border: !temp ? '1px solid grey' : ''
              }}
              className='at-project-mngmt__assessment-status'>
                Final Assessment
            </p>
          </Tooltip>
        </div>
      )
    }
  },
  { header: getTranslation( language, 'Overall Progress' ),
    renderColumn: rowData => {
      let currentKPI = 0
      let targetKPI = 0
      rowData.stage_and_milestone?.map(stage => {
        stage.stage_milestone?.map(milestone => {
          currentKPI += parseInt(milestone.milstone_actual_kpi || 0)
          targetKPI += parseInt(milestone.milstone_kpi || 0)
        })
      })

      return (
        <div
          style={{ width: 100, textAlign: 'center', paddingBottom: 16, fontWeight: 500 }}>
          <Doughnut
            options={{
              cutout: '40%',
              plugins: {
                legend: { display: false }
              }
            }}
            data={{
              labels: [ 'Current', 'Remaining' ],
              datasets: [{
                data: [
                  currentKPI,
                  targetKPI - currentKPI
                ],
                backgroundColor: [ '#ff8d00', '#c32222' ]
              }]
            }}
          />
          {currentKPI}/{targetKPI}
        </div>
      )
    }
  }
]
import { useState } from 'react'
import localStorage from 'localStorage'
import { Modal, Button, Form } from "react-bootstrap"

import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )

const Confirmation = (props) => {
  const [remark, setRemark] = useState('')

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{ getTranslation( language, 'Are you sure?' ) }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.confirmationText}
        {props.showRemark && (
          <Form.Group controlId="remark" className='mt-2'>
            <Form.Control
              as="textarea"
              rows={5}
              value={remark}
              style={{ height: '100px' }}
              placeholder='Enter Remark'
              onChange={(e) => setRemark(e.target.value)}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          { getTranslation( language, 'Cancel' ) }
        </Button>
        <Button
          variant="outline-primary"
          onClick={ () => props.onClick(remark || '') }>
          { getTranslation( language, 'Confirm' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Confirmation

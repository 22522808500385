import React, { Component } from 'react'
import { signOut, getAuth } from "firebase/auth";

import CustomSnackbar from 'components/Snackbar';

import { Post } from 'utils/axios'
import { getUser } from 'actions';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    
    changeUserCredential = dataToSubmit => Post(
      `/changeUserCredential`,
      dataToSubmit,
      this.changeUserCredentialSuccess,
      this.changeUserCredentialError,
      this.load
    )
    changeUserCredentialSuccess = payload => {
      console.log( payload.data )
      this.requestSuccess( 'Email/Password Updated')
    }
    changeUserCredentialError = error => this.requestError( error.message )

    updateProfile = dataToSubmit => Post(
      `/updateUser`,
      dataToSubmit,
      () => this.updateProfileSuccess( dataToSubmit.uid ),
      this.updateProfileError,
      this.load
    )
    updateProfileSuccess = uid => {
      getUser( uid, this.requestError, this.load )
      this.requestSuccess( 'User Details Updated' )
    }

    signOutUser = () => {
      let auth = getAuth()
      localStorage.removeItem( 'userLogin' )
      this.requestSuccess( 'Sign Out Successful' )
      signOut( auth )
    }
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoading={ this.state.loading }

            signOutUser={ this.signOutUser }
            updateProfile={ this.updateProfile }
            changeUserCredential={ this.changeUserCredential }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  return WithHOC
}

export default HOC
import React, { useState } from 'react'
import _ from 'lodash'
import { Form, Modal, Button } from "react-bootstrap"

const fieldData = [
  { label: 'Display Name', value: 'display_name' },
  { label: 'Mobile No.', value: 'mobile_no' },
]

const UpdateProfile = (props) => {

  const [ userDetail, setUserDetail ] = useState( props.user )
  const [ error, setError ] = useState({})

  const handleSubmit = () => Promise.all([
    fieldData.map( item => {
      if( userDetail[ item.value ] === '' ){
        setError({ [ item.value ]: `${ item.label } should not left empty.` })
      }
    })
  ]).then( () => {
    if( !_.values( userDetail ).some( x => !x || x === '' ) ){
      props.updateProfile( userDetail )
      props.setOpen( false )
    }
  })

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( userDetail )
    temp[ context ] = val
    setUserDetail( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen( false )}>
      <Modal.Header closeButton>
        <Modal.Title>Update Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          fieldData.map(( item, index ) => {
            return(
              <Form.Group key={ index } className='mt-3'>
                <Form.Label>{ item.label }</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={ item.label }
                  value={ userDetail[ item.value ] }
                  onChange={ e => onChangeField( e.target.value, item.value ) }
                />
                { error[ item.value ] && <Form.Text>{ error[ item.value ] }</Form.Text>}
              </Form.Group>
            )
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button disabled={ _.values( userDetail ).some( x => !x || x === '' ) } variant="outline-primary" onClick={ handleSubmit }>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateProfile;
export const renderBackground = status => {
  switch( status ){
    case 'Not Started':
      return '#adb5bd'
    case 'On Track':
      return '#2dd16b'
    case 'Over Achievement':
      return '#2dc5d1'
    case 'Delayed < 2 weeks':
      return '#f9c828'
    case 'Delayed > 2 weeks':
      return '#f93728'
    case 'Completed':
      return '#6b28f9'
    default:
      return '#adb5bd'
  }
}

export const badgeStatus = status => {
  switch(status){
    case 'Pending':
      return {
        background: '#ffc1c2',
        color: '#c30010'
      }
    case 'Draft':
      return {
        background: '#dddddd',
        color: '#515151'
      }
    case 'Submitted':
      return {
        background: '#abf7b1',
        color: '#008631'
      }
    case 'Complete':
      return {
        background: '#abf7b1',
        color: '#008631'
      }
    case 'On-Progress':
      return {
        background: '#fffcae',
        color: '#ffbc00'
      }
    case 'In Review':
      return {
        background: '#dddddd',
        color: '#515151'
      }
    default:
      return {
        background: '#dddddd',
        color: '#515151'
      }
  }
}

export const assessmentStatus = status => {
  switch(status){
    case 'Poor':
      return {
        background: '#ffc1c2',
        color: '#c30010'
      }
    case 'Exceed Target':
      return {
        background: '#abf7b1',
        color: '#008631'
      }
    case 'Significantly Exceed Target':
      return {
        background: '#abf7b1',
        color: '#008631'
      }
    case 'On Target':
      return {
        background: '#f9ddb1',
        color: '#dc6601'
      }
    case 'Below Target':
      return {
        background: '#fffcae',
        color: '#ffbc00'
      }
    case 'Assessment Is Not Made':
      return {
        background: '#dddddd',
        color: '#515151'
      }
    default:
      return {
        background: '#dddddd',
        color: '#515151'
      }
  }
}

export const assessmentStatusId = status => {
  switch(status){
    case '1':
      return {
        label: 'Poor',
        background: '#ffc1c2',
        color: '#c30010'
      }
    case '4':
      return {
        label: 'Exceed Target',
        background: '#abf7b1',
        color: '#008631'
      }
    case '5':
      return {
        label: 'Significant Exceed Target',
        background: '#abf7b1',
        color: '#008631'
      }
    case '3':
      return {
        label: 'On Target',
        background: '#f9ddb1',
        color: '#dc6601'
      }
    case '2':
      return {
        label: 'Below Target',
        background: '#fffcae',
        color: '#ffbc00'
      }
    default:
      return {
        label: '-',
        background: '#dddddd',
        color: '#515151'
      }
  }
}

export const renderScoreStatus = score => {
  if( score <= 30 )
    return 'Poor'
  else if( score <= 50 && score >= 31 )
    return 'Below Target'
  else if( score <= 70 && score >= 51 )
    return 'On Target'
  else if( score <= 90 && score >= 71 )
    return 'Exceed Target'
  else if( score <= 100 && score >= 91 )
    return 'Significantly Exceed Target'
  else
    return 'Assessment Is Not Made'
}
import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'moment'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'
import { renderScoreStatus } from 'utils/stageStatus'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      kpi: [],
      activity_reports: [],
      summary_year_options: [],
      activity_reports_summary: {},

      loading: false,
      loadingCount: 0,
      projectDetail: {
        project_name: '',
        project_description: '',
        implementing_agency: '',
        assessor: '',
        project_leader: '',
        current_stage: '',
        start_year: '',
        funder: '',
        state: '',
        status: '',
        activity_report: [],
        kpi_indication: [],
        achievement_indication: [],
        disbursement_progress: [],
        utilization_progress: [],
        stage_and_milestone: [],
        assessment: []
      },
      assessment: {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
      },
      selectedYear: new Date(),
      selectedFile: '',
      selectedFilename: '',
      showPreviewModal: false,
      showSubmitConfirmation: false,
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectAssessmentHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjectDetail = id => Get(
      `/project-getProject?project_id=${ id }`,
      this.getProjectDetailSuccess,
      this.getProjectDetailError,
      this.load
    ) 
    getProjectDetailSuccess = payload => this.setState({ projectDetail: payload.data })
    getProjectDetailError = error => this.requestError( error )

    getActivityReports = reports => Get(
      `/activity_report-getActivityReports?reports=${ JSON.stringify( reports ) }&project_id=${ this.state.projectDetail.project_id }`,
      this.getActivityReportsSuccess,
      this.getActivityReportsError,
      this.load
    )
    getActivityReportsSuccess = payload => {
      let temp = _.cloneDeep( payload.data.reports )
      let yearOptions = []
      let summary = {}
      temp.map( report => {
        let month = Moment( report.submited_date ).format( 'MMM' )
        let year = Moment( report.submited_date ).format( 'YYYY' )
        if( yearOptions.includes( year ) ){
          if( summary[ year ] ){
            summary[ year ][ month ] = report
          } else {
            summary[ year ] = {}
            summary[ year ][ month ] = report
          }
        } else {
          yearOptions.push( year )
          summary = {
            [ year ]:{
              [ month ]: report
            } 
          }
        }
      })

      this.setState({ 
        activity_reports: payload.data.reports,
        activity_reports_summary: summary,
        summary_year_options: yearOptions,
      })
    }
    getActivityReportsError = error => this.requestError( error )

    getKPIs = kpis => Get(
      `/kpi-getKPIs?kpis=${ JSON.stringify( kpis ) }`,
      this.getKPIsSuccess,
      this.getKPIsError,
      this.load
    )
    getKPIsSuccess = payload => this.setState({ kpi: payload.data.kpi })
    getKPIsError = error => this.requestError( error )

    getAssessment = id => Get(
      `/assessment-getAssessment?assessment_id=${ id }`,
      this.getAssessmentSuccess,
      this.getAssessmentError,
      this.load
    )
    getAssessmentSuccess = payload => this.setState({
      assessment: payload.data,
      selectedYear: new Date( Moment( payload.data.created_at.substring( 1, payload.data.created_at.length ), 'Q, yyyy' ) ),
    })
    getAssessmentError = error => this.requestError( error )
    
    submitAssessment = ( onNaviagte ) => {
      const user = JSON.parse( localStorage.getItem( 'userLogin' ) )

      Post(
        `/assessment-updateAssessment`,
        {
          ...this.state.assessment,
          updated_at: Moment().format( 'DD MMM YYYY' ),
          status: renderScoreStatus( this.state.assessment[ 6 ].jumlah_markah ),
          date: `Q${ Moment( this.state.selectedYear ).format( 'Q, yyyy' ) }`,
          project_id: this.state.projectDetail.project_id,
          user_uuid: user.uid
        },
        payload => this.submitAssessmentSuccess( payload, onNaviagte ),
        this.submitAssessmentError,
        this.load
      )
    }
    submitAssessmentSuccess = ( payload, onNaviagte ) => {
      onNaviagte( `/dashboard/assessor/project-management/${ this.state.projectDetail.project_id }` )
      this.requestSuccess( 'Assessment Created' )
    }
    submitAssessmentError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectAssessment={ this.state.loading }

            getKPIs={ this.getKPIs }
            getAssessment={ this.getAssessment }
            getProjectDetail={ this.getProjectDetail }
            submitAssessment={ this.submitAssessment }
            getActivityReports={ this.getActivityReports }
            onChangeProjectAssessmentHOC={ this.onChangeProjectAssessmentHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form } from "react-bootstrap"
import localStorage from 'localStorage'
import { 
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import DatePicker  from 'react-datepicker'
import { FaFilePdf } from 'react-icons/fa'
import { useParams, useOutletContext } from 'react-router-dom';
import { Plagiarism, NavigateBefore, Clear } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import PreviewModal from '../../components/preview'

import SiteAuditHOC from './actions'
import { getTranslation } from 'utils/renderLanguage'
import { gradeOptions, assessorAuditFieldData } from '../assets'
import '../index.scss'

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const language = localStorage.getItem( 'english' )

const CreateSiteAudit = props => {
  
  const outletContext = useOutletContext()
  const {
    project_id: selectedProjectId,
    site_audit_id
  } = useParams()

  const [ numPages, setNumPages ] = useState( null )
  const [ pageNumber, setPageNumber ] = useState( 1 )

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  
  useEffect( () => {
    Promise.all([
      props.getProjectDetail( selectedProjectId )
    ]).then( () => {
      props.getSiteAudit( site_audit_id )
    })
  }, [])

  const renderFieldInput = ( item, section, index ) => {
    if( item.type === 'textarea' ) {
      return(
        <Form.Group key={ index } className='mt-3'>
          <Form.Label>{ item.label }</Form.Label>
          <Form.Control
            as='textarea'
            type={ 'text' }
            style={{ minHeight: 100 }}
            disabled={ true }
            placeholder={ item.label }
            value={ props.siteAudit[ item.value ] }
          />
        </Form.Group>
      )
    } else if ( item.type === 'radio' ) {
      return (
        <Form.Group key={ index } className='mt-3'>
          <Form.Label>{ item.label }</Form.Label>
          <RadioGroup
            row
            style={{ marginLeft: 20 }}
            value={ props.siteAudit[ item.value ] } >
            {
              gradeOptions.map(( option, index ) => {
                return(
                  <FormControlLabel disabled={ true } classes={{ label: 'radio-label' }} key={ index } value={ option.id } control={ <Radio size="small" /> } label={ getTranslation( language, option.label ) } />
                )
              })
            }
          </RadioGroup>
        </Form.Group>
      )
    } else {
      return(
        <Form.Group key={ index } className='mt-3'>
          <Form.Label>{ item.label }</Form.Label>
          <Form.Control
            type={ item.type }
            placeholder={ item.label }
            maxLength={ item.type === 'number' ? 3 : '' }
            disabled={ true }
            value={ props.siteAudit[ item.value ] }
          />
        </Form.Group>
      )
    }
  }
  
  const renderCriteriaColumn = () => {

    return(
      <>
        <div className='site-audit__section-cont'>
          <label className='title'>{ getTranslation( language, 'Based on your assessment, kindly provide your comment as follows:' ) }</label>
          {
            assessorAuditFieldData.map(( item , index ) => (
              renderFieldInput( item, index )
            ))
          }
        </div>
        <div className='row my-2'>
          <Form.Group className='col-md-6'>
            <Form.Label>{ getTranslation( language, 'Assessor Name' ) }</Form.Label>
            <Form.Control
              type={ 'text' }
              disabled={ true }
              value={ props.siteAudit.assessor_name }/>
          </Form.Group>
          <Form.Group className='col-md-6'>
            <Form.Label>{ getTranslation( language, 'Date' ) }</Form.Label>
            <Form.Control
              type={ 'text' }
              disabled={ true }
              value={ props.siteAudit.summission_date }/>
          </Form.Group>
          <Form.Group className='col-md-12 mt-3'>
            <Form.Label>{ getTranslation( language, 'Total Score' ) }</Form.Label>
            <Form.Control
              type={ 'text' }
              disabled={ true }
              value={ props.siteAudit.jumlah_markah }/>
          </Form.Group>
        </div>
        <Form.Group className='mt-4 mb-5'>
          <Form.Check
            type={ 'checkbox' }
            style={{ fontWeight: 500 }}
            disabled={ true }
            label={
              <>
                { getTranslation( language, 'I confirm the assessment was made.' ) }<br/>
                { getTranslation( language, 'I hereby confirm the evaluation confirm the quotation documents and have examined all the documents submitted.' ) }
              </>
            }
            checked={ props.siteAudit.akuan }/>
        </Form.Group>
      </>
    )
  }
  
  return(
    <>
      <div className="d-flex align-items-center mb-5">
        <button className="back-button" onClick={ () => outletContext.onNavigate( -1 ) }>
          <NavigateBefore/>
        </button>
        <h4>
          { getTranslation( language, 'Site Audit Assessment' ) }
          <span style={{ fontSize: 14, marginLeft: 5 }}>/ { selectedProjectId }</span>
        </h4>
      </div>
      <div className='d-flex justify-content-between mb-4'>
        <div className='year-selection'>
          <label>{ getTranslation( language, 'Year' ) }</label>
          <DatePicker
            className='form-date-picker'
            selected={ props.selectedYear }
            disabled={ true }
            onChange={ date => props.onChangeSiteAuditHOC( date, 'selectedYear' ) }
            dateFormat="QQQ, yyyy"
            showQuarterYearPicker
          />
        </div>
        <button
          className='btn btn-primary mt-auto'
          onClick={ () => props.onChangeSiteAuditHOC( true, 'showPreviewModal' ) }>
          <Plagiarism /> { getTranslation( language, 'Preview' ) }
        </button>
      </div>
      <div className='row mt-4 mx-1'>
        {
          props.selectedFile && (
            <div className='col-lg-8'>
              <div className='react-pdf-viewer__filename'>
                <FaFilePdf />{ props.selectedFilename || 'No file name' }
                <Clear style={{ cursor: 'pointer', marginLeft: 'auto', color: 'grey' }} onClick={ () => props.onChangeSiteAuditHOC( '', 'selectedFile' ) } />
              </div>
              <Document file={ props.selectedFile } onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={ pageNumber } />
              </Document>
              <div className='react-pdf-viewer__navigation'>
                <button
                  style={{ width: 100 }}
                  className='btn btn-outline-secondary mr-2'
                  disabled={ pageNumber === 1 }
                  onClick={ () => setPageNumber( pageNumber - 1 ) }>
                  { getTranslation( language, 'Previous' ) }
                </button>
                <b className='m-2'>{ pageNumber }</b> / <b className='m-2'>{ numPages }</b>
                <button
                  style={{ width: 100 }}
                  className='btn btn-outline-secondary ml-2'
                  disabled={ pageNumber === numPages }
                  onClick={ () => setPageNumber( pageNumber + 1 ) }>
                  { getTranslation( language, 'Next' ) }
                </button>
              </div>
            </div>
          )
        }
        <div className={ `${ props.selectedFile ? 'col-lg-4' : 'col-lg-12' } site-audit__audit-cont` }>
          { renderCriteriaColumn() }
        </div>
      </div>
      {
        props.showPreviewModal && <PreviewModal
          kpi={ props.kpi }
          projectDetail={ props.projectDetail }
          activity_reports={ props.activity_reports }
          summary_year_options={ props.summary_year_options }
          activity_reports_summary={ props.activity_reports_summary }
          onLoadProjectAssessment={ props.onLoadProjectAssessment }

          getKPIs={ props.getKPIs }
          getActivityReports={ props.getActivityReports }
          onChangeHOC={ props.onChangeSiteAuditHOC }
          setOpen={ val => props.onChangeSiteAuditHOC( val, 'showPreviewModal' ) }  
        />
      }
      {
        props.onLoadSiteAudit && <LoadingModal />
      }
    </>
  )
}

export default SiteAuditHOC( CreateSiteAudit );